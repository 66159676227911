import axios from "axios";
import {SignInValues} from "./type";

const postSignIn = async (data: SignInValues) => {
    try {
        await axios.post("/users/login/", data)
    } catch (err) {
        throw new Error("err");
    }
}

export default  postSignIn;