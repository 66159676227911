import {Dayjs} from "dayjs";
import {OptionObjectType} from "./common-types";

export const getFormData = (object: any) => {
    const formData = new FormData();
    Object.keys(object).forEach(key => formData.append(key, object[key]));
    return formData;
}

export const generateId = () => `id${Math.random().toString(16).slice(2)}`;

export const addToClipBoard = (value: string) => {
    if (navigator) {
        navigator.clipboard.writeText(value);
    }
};

export const handleChangeInnerField = (key: string, fieldKey: string, value: string | number | boolean | Dayjs | null, setState: (state: any) => void) => {
    setState((prevValue: any) => ({
        ...prevValue,
        [key]: {
            ...(typeof prevValue[key] === "object" ? prevValue[key] : {}),
            [fieldKey]: value,
        },
    }));
};

export const handleChangeField = (key: any, value: any, state: any, setState: (item: any) => void) => {
    setState({
        ...state,
        [key]: value,
    });
};


export const parseLabelKey = (item: string | OptionObjectType) => {
    let label;
    let key;
    if (typeof item === "object" && item !== null) {
        label = item.name;
        key = item.id;

        if (!label) {
            label = key;
        }
    } else {
        label = item;
        key = item;
    }

    return { label, key };
}

export const parseError = (data: any) => {
    const res: any = [];
    Object.keys(data).forEach(key => {
        if (Array.isArray(data[key])) {
            data[key].forEach((error: any) => res.push(error));
        } else {
            const tab = Object.values(data[key]);
            tab.forEach((error: any) => {
                res.push(error[0]);
            })
        }
    })
    return res;
}