import axios from "axios";
import {FilterType} from "../../hooks/useFilter";

// eslint-disable-next-line import/prefer-default-export
export const getReport = async (filters: FilterType) => {
    try {
        const res = await axios.get("/users/statistics/", {params: filters});
        return await res.data;
    } catch (err) {
        throw new Error("Error getRelatedSearchesTable");
    }
}