import React, {ChangeEvent, FC, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Box, FormControl, IconButton, MenuItem, Select, SwipeableDrawer} from "@mui/material";
import dayjs from "dayjs";
import Tabs from "../Tabs";
import {FilterType} from "../../hooks/useFilter";
import {tabTypeItems} from "../../services/constants";
import {ReactComponent as FiltersIcon} from "../../assets/icons/filters-icon.svg";
import {ReactComponent as CloseIcon} from "../../assets/icons/close-icon.svg";
import DatePickerCustom from "../DatePicker";

type FiltersType = {
    filters: FilterType,
    regions: string[],
    statuses: {label: string, value: string}[],
    levels: {label: string, value: string, Icon: any}[],
    pitsSignsItems?: {label: string, value: string, Icon: any}[],
    handleSelectChange: (key: string, value: string) => void,
    handleCheckboxChange: (key: string, event: ChangeEvent<HTMLInputElement>) => void,
    handleChangeMobile?: (value: any) => void,
    exclude?: string[],
    isTabs?: boolean
}

const Filters: FC<FiltersType> = ({isTabs=true, exclude=[], filters, handleChangeMobile=() => {}, handleSelectChange, handleCheckboxChange, statuses, pitsSignsItems, levels, regions}) => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [stepType, setStepType] = useState(searchParams.has("type") ? tabTypeItems.findIndex(tab => tab === searchParams.get("type")) : 0);
    const [openMobile, setOpenMobile] = useState(false);
    const [filtersMobile, setFiltersMobile] = useState({region_name: "", status: "", type: ["road_pits", "path_signs"],
        level: ["Маленькая яма", "Средняя яма", "Большая яма", "Разметка пешехода стирается", "Стрелка поворота стирается"]});

    const handleChangeType = (e: any, value: number) => {
        setStepType(value);
        setSearchParams(params => {
            params.set("type", tabTypeItems[value]);
            return params;
        });
    }

    const handleChangeFilterMobile = (fieldType: "select" | "checkbox", key: string, val: string) => {
        let value = val;
        if (fieldType === "checkbox") {
            // eslint-disable-next-line prefer-destructuring
            value = value.split("-")[1];
        }
        setFiltersMobile({
            ...filtersMobile,
            [key]: fieldType === "select"
                ? value
                : (() => {
                    let newLevels = [...(filtersMobile as any)[key]];
                    if (newLevels.includes(value)) {
                        newLevels = newLevels.filter(lvl => lvl !== value);
                    } else {
                        newLevels.push(value);
                    }
                    return newLevels
                })()
        })
    }

    const handleSubmitFiltersMobile = () => {
        handleChangeMobile(filtersMobile);
        handleOpenMobileFilters();
    }

    const handleOpenMobileFilters = () => {
        setOpenMobile(prev => !prev)
    }
    return (
        <>
            { isTabs &&
                <Tabs step={stepType} onChange={handleChangeType} items={tabTypeItems.map(el => t(el))} />
            }
            <div className="flex md:hidden">
                <button type="button"
                    onClick={handleOpenMobileFilters}
                    className="gap-[10px] text-[14px] text-[rgba(107, 114, 128, 1)] rounded-lg border-solid
                    border-[1px] border-[rgba(209, 213, 219, 1)] w-full py-[9px] flex items-center justify-center"
                >
                    {t("filters")}
                    <FiltersIcon />
                </button>
                <SwipeableDrawer
                    sx={{".MuiPaper-root": {borderTopLeftRadius: "8px", borderTopRightRadius: "8px"}}}
                    anchor="bottom"
                    open={openMobile}
                    onClose={handleOpenMobileFilters}
                    onOpen={handleOpenMobileFilters}
                >
                    <Box
                        sx={{ width: "auto", p: "1.25rem 1rem"}}
                        role="presentation"
                        // onClick={handleOpenMobileFilters}
                        onKeyDown={handleOpenMobileFilters}
                    >
                        <Box sx={{mb: "1rem", display: "flex", fontWeight: "500", justifyContent: "space-between", alignItems: "center"}}>
                            {t("filters")}
                            <IconButton onClick={handleOpenMobileFilters}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <div className="flex gap-[1rem] items-center flex-wrap">
                            { !exclude.includes("date") &&
                                <>
                                    <DatePickerCustom
                                        value={filters.date_before as string}
                                        onChange={newValue => handleChangeFilterMobile("select", "date_before", dayjs(newValue).format("YYYY-MM-DD"))}
                                        style={{  width: "100%", paddingTop: "-8px" }}
                                        from
                                    />
                                    <DatePickerCustom
                                        value={filters.date_after as string}
                                        onChange={newValue => handleChangeFilterMobile("select", "date_after", dayjs(newValue).format("YYYY-MM-DD"))}
                                        style={{  width: "100%", paddingTop: "-8px" }}
                                        to
                                    />
                                </>
                            }
                            { !exclude.includes("region") &&
                                <FormControl fullWidth>
                                    <Select
                                        className="py-[.625rem] px-[1rem] w-full h-[42px] bg-gray-50 text-gray-900 text-sm !rounded-lg focus:ring-blue-500
                        focus:border-blue-500 block p-2.5"
                                        id="demo-simple-select"
                                        sx={{
                                            ".css-11c8fbq-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
                                                p: "0"
                                            }
                                        }}
                                        placeholder="Все регионы"
                                        value={filters.region_name}
                                        displayEmpty
                                        renderValue={selected => {
                                            if (!selected) {
                                                return t("all_regions");
                                            }
                                            return selected;
                                        }}
                                        onChange={e => handleChangeFilterMobile("select", "region_name", e.target.value)}
                                    >
                                        <MenuItem value="">{t("all_regions")}</MenuItem>
                                        { regions.map((item, index) => (
                                            <MenuItem key={index} value={item}>{item}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            }
                            { !exclude.includes("status") &&
                                <FormControl fullWidth>
                                    <Select
                                        className="py-[.625rem] px-[1rem] w-full h-[42px] bg-gray-50 text-gray-900 text-sm !rounded-lg focus:ring-blue-500
                        focus:border-blue-500 block p-2.5"
                                        id="demo-simple-select"
                                        sx={{
                                            ".css-11c8fbq-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
                                                p: "0"
                                            }
                                        }}
                                        value={t(filters.status)}
                                        displayEmpty
                                        renderValue={selected => {
                                            if (!selected) {
                                                return t("all_statuses");
                                            }
                                            return selected;
                                        }}
                                        onChange={e => handleSelectChange("status", e.target.value)}
                                    >
                                        { statuses.map((item, index) => (
                                            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            }
                            { !exclude.includes("pits_signs") &&
                                <div className="flex w-full gap-[1rem] flex-col">
                                    { levels.map((item, index) => (
                                        <div key={index} className="flex items-center gap-[.5rem]">
                                            <input
                                                checked={filtersMobile.level.includes(item.value)}
                                                onChange={e => handleChangeFilterMobile("checkbox", "level", e.target.id)}
                                                type="checkbox" value=""
                                                id={`${index}-${item.value}`}
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                                            />
                                            <label htmlFor={`${index}-${item.value}`} className="text-sm font-medium text-gray-900 dark:text-gray-300">{item.label}</label>
                                            <item.Icon />
                                        </div>
                                    ))}
                                </div>
                            }
                            { !exclude.includes("pits_signs_gen") &&
                                <div className="flex w-fit gap-[1rem] items-center flex-wrap">
                                    { pitsSignsItems?.map((item, index) => (
                                        <div key={index} className="flex items-center gap-[.5rem]">
                                            <input
                                                checked={filtersMobile.type?.includes(item.value)}
                                                onChange={e => handleChangeFilterMobile("checkbox", "type", e.target.id)}
                                                id={`${index}-${item.value}`} type="checkbox" value=""
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500
                                dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                            />
                                            <label htmlFor={`${index}-${item.value}`} className="text-sm font-medium text-gray-900 dark:text-gray-300">{item.label}</label>
                                            <item.Icon />
                                        </div>
                                    ))}
                                </div>
                            }
                            <button
                                onClick={handleSubmitFiltersMobile}
                                type="button" className="w-full rounded-lg bg-primary text-white pt-[10px] pb-[10px]"
                            >
                                {t("find")}
                            </button>
                        </div>
                    </Box>
                </SwipeableDrawer>
            </div>
            <div className="hidden md:flex gap-[1.5rem] items-center flex-wrap">
                { !exclude.includes("date") &&
                    <>
                        <DatePickerCustom
                            value={filters.date_before as string}
                            onChange={newValue => handleSelectChange("date_before", dayjs(newValue).format("YYYY-MM-DD"))}
                            style={{  width: "360px", paddingTop: "-8px" }}
                            from
                        />
                        <DatePickerCustom
                            value={filters.date_after as string}
                            onChange={newValue => handleSelectChange("date_after", dayjs(newValue).format("YYYY-MM-DD"))}
                            style={{  width: "360px", paddingTop: "-8px" }}
                            to
                        />
                    </>
                }
                { !exclude.includes("region") &&
                    <FormControl>
                        <Select
                            className="py-[.625rem] px-[1rem] w-[220px] h-[42px] bg-gray-50 text-gray-900 text-sm !rounded-lg focus:ring-blue-500
                        focus:border-blue-500 block p-2.5"
                            id="demo-simple-select"
                            sx={{
                                ".css-11c8fbq-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
                                    p: "0"
                                }
                            }}
                            placeholder="Все регионы"
                            value={filters.region_name}
                            displayEmpty
                            renderValue={selected => {
                                if (!selected) {
                                    return t("all_regions");
                                }
                                return selected;
                            }}
                            onChange={e => handleSelectChange("region_name", e.target.value)}
                        >
                            <MenuItem value="">Все регионы</MenuItem>
                            { regions.map((item, index) => (
                                <MenuItem key={index} value={item}>{item}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                }
                { !exclude.includes("status") &&
                    <FormControl>
                        <Select
                            className="py-[.625rem] px-[1rem] w-[230px] h-[42px] bg-gray-50 text-gray-900 text-sm !rounded-lg focus:ring-blue-500
                        focus:border-blue-500 block p-2.5"
                            id="demo-simple-select"
                            sx={{
                                ".css-11c8fbq-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
                                    p: "0"
                                }
                            }}
                            value={t(filters.status)}
                            displayEmpty
                            renderValue={selected => {
                                if (!selected) {
                                    return t("all_statuses");
                                }
                                return selected;
                            }}
                            onChange={e => handleSelectChange("status", e.target.value)}
                        >
                            { statuses.map((item, index) => (
                                <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                }
                { !exclude.includes("pits_signs") &&
                    <div className="flex w-fit gap-[1rem] items-center flex-wrap">
                        { levels.map((item, index) => (
                            <div key={index} className="flex items-center gap-[.5rem]">
                                <input
                                    checked={filters.level.includes(item.value)}
                                    onChange={e => handleCheckboxChange("level", e)}
                                    id={item.value} type="checkbox" value=""
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500
                                dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label htmlFor={item.value} className="text-sm font-medium text-gray-900 dark:text-gray-300">{item.label}</label>
                                <item.Icon />
                            </div>
                        ))}
                    </div>
                }
                { !exclude.includes("pits_signs_gen") &&
                    <div className="flex w-fit gap-[1rem] items-center flex-wrap">
                        { pitsSignsItems?.map((item, index) => (
                            <div key={index} className="flex items-center gap-[.5rem]">
                                <input
                                    checked={filters.type?.includes(item.value)}
                                    onChange={e => handleCheckboxChange("type", e)}
                                    id={item.value} type="checkbox" value=""
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500
                                dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label htmlFor={item.value} className="text-sm font-medium text-gray-900 dark:text-gray-300">{item.label}</label>
                                <item.Icon />
                            </div>
                        ))}
                    </div>
                }
            </div>
        </>
    )
}

export default Filters;