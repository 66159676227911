export const lightThemeOptions = {
    text: {
        primary: "#111928",
        secondary: "#6B7280",
        inverse: "#fff",
        // хлебные крошки и тд
        disabled: "#666666",
        error: "#d32f2f"
    },
    background: {
        paper: "#FFFFFF",
        default: "#FFFFFF",
    },
    customBackground: {
        main: "#ECECEF",
        primary: "#FFFFFF",
        secondary: "#FBFAFD",
        inverse: "#ECECEF",
        surface: "rgba(253, 186, 47, 0.08)"
    },
    brandColor: {
        primary: "#1C64F2",
        secondary: "#002D55",
    },
    status: {
        success: "#66D670",
        info: "#002D55",
        error: "#F34E4E",
        warning: "#1C64F2"
    },
    border: {
        primary: "#ECECEF",
        secondary: "#1C64F2"
    }
}

export const darkThemeOptions = {
    text: {
        primary: "#FFFFFF",
        secondary: "#FFFFFF",
        inverse: "#000",
        disabled: "#A1A1A1",
        error: "#d32f2f"
    },
    background: {
        paper: "#121212",
        default: "#121212"
    },
    customBackground: {
        main: "#121212",
        primary: "#121212",
        secondary: "#121212",
        inverse: "#272727"
    },
    brandColor: {
        primary: "#1C64F2",
        secondary: "#002D55",
    },
    status: {
        success: "#66D670",
        info: "#002D55",
        error: "#F34E4E",
        warning: "#FDBA2F"
    },
    border: {
        primary: "#2E2E2E",
        secondary: "rgba(253, 186, 47, 0.5)"
    }
}