import { useLocation} from "react-router-dom";
import {List, ListItem, Collapse, Divider} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import * as React from "react";
import {FC, useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {AuthContext} from "../../context/AuthContext";
import Item from "./Item";
import navLinks from "../../services/navLinks";
import {ReactComponent as ProfileIcon} from "../../assets/icons/menu-profile.svg";
import {ReactComponent as QuitIcon} from "../../assets/icons/menu-quit.svg";

const ItemsList: FC<{open: boolean, logout?: any}> = ({open=false, logout}) => {
    const { t } = useTranslation();
    const {me: {permissions, name}} = useContext(AuthContext);
    const {pathname} = useLocation();
    const [openSubMenus, setOpenSubMenus] = useState<Record<string, boolean>>({});

    useEffect(() => {
        if (pathname) {
            navLinks.forEach(item => {
                if (pathname.includes(item.key)) {
                    setOpenSubMenus(prevState => ({ ...prevState, [item.key]: true }));
                } else if (item.children && item.children.length > 0) {
                    item.children.forEach(child => {
                        if (pathname.includes(child.key)) {
                            setOpenSubMenus(prevState => ({ ...prevState, [item.key]: true }));
                        }
                    })
                }
            })
        }
    }, [])

    const handleToggleSubMenu = (key: string) => {
        setOpenSubMenus(prevState => ({ ...prevState, [key]: !prevState[key] }));
    };
    const theme = useTheme();
    return (
        <List>
            {navLinks.slice(0, navLinks.length - 1).map((item, index) => {
                const disabled = item.permission ? !permissions[item.permission] : false;
                return (
                    <React.Fragment key={index}>
                        <ListItem disablePadding sx={{ display: "block" }}>
                            <Item disabled={disabled} item={item} handleToggleSubMenu={handleToggleSubMenu} theme={theme} open={open} openSubMenus={openSubMenus} />
                        </ListItem>
                        {item.children && (
                            <Collapse in={openSubMenus[item.key]} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {item.children.map((child, childIndex) => {
                                        const disabled = child.permission ? !permissions[child.permission] : false;
                                        return <Item key={childIndex} disabled={disabled} item={child} handleToggleSubMenu={handleToggleSubMenu} theme={theme} open={open} />
                                    })}
                                </List>
                            </Collapse>
                        )}
                    </React.Fragment>
                )
            })}
            <Divider sx={{mt: "6px", mb: "24px"}}/>
            <List component="div" disablePadding>
                <Item disabled={false} item={{
                    label: name,
                    key: "profile",
                    href: "/profile",
                    Icon: ProfileIcon
                }} handleToggleSubMenu={handleToggleSubMenu} theme={theme} open={open} openSubMenus={openSubMenus} />
            </List>
            <List component="div" disablePadding>
                <Item disabled={false} item={{
                    label: "quit",
                    key: "quit",
                    href: "/quit",
                    Icon: QuitIcon
                }} handleToggleSubMenu={logout} theme={theme} open={open} openSubMenus={openSubMenus} />
            </List>
        </List>
    )
}

export default ItemsList;