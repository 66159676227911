import {Box, Button, IconButton, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import React from "react";
import {useTranslation} from "react-i18next";
import {ReactComponent as PinOrange} from "../../assets/icons/pin-orange.svg";
import {ReactComponent as PinGreen} from "../../assets/icons/pin-green.svg";
import {ReactComponent as PinRed} from "../../assets/icons/pin-red.svg";
import {ReactComponent as CloseIcon} from "../../assets/icons/close-icon.svg";

const ItemDetails = ({itemDetails, handleClose, onFixedHandler, getButtonLabel}: any) => {
    const { t } = useTranslation();
    return (
        <>
            <Box sx={{mb: "1rem", display: "flex", fontWeight: "500", justifyContent: "space-between", alignItems: "center"}}>
                <Box>
                    { itemDetails.repaired_by &&
                        <Typography fontWeight={600}>{itemDetails.repaired_by}</Typography>
                    }
                    <Typography fontWeight={500}
                        sx={{
                            color: itemDetails.status === "Ожидает"
                                ? "#D03801"
                                : itemDetails.status === "В работе"
                                    ? "#047481"
                                    : itemDetails.status === "На проверке"
                                        ? "orange"
                                        : "#1C64F2"
                        }}
                    >
                        {itemDetails.status}
                    </Typography>
                </Box>
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Box sx={{mb: "1rem", position: "relative", width: "100%", height: "352px", borderRadius: "4px", overflow: "hidden"}}>
                <Link
                    to={`https://backend.jol-almaty.kz/${itemDetails.image}`} target="_blank">
                    <img
                        style={{position: "absolute", width: "100%", height: "100%", top: "0", left: "0"}}
                        src={`https://backend.jol-almaty.kz/${itemDetails.image}`}
                        alt="pit"
                    />
                </Link>
            </Box>
            <Box className="flex gap-[.25rem] flex-col">
                <Box className="flex items-center gap-[.75rem]">
                    <p className="text-[#6B7280]">{t("latitude")}:</p>
                    <p className="font-semibold text-base">{parseFloat(itemDetails.latitude).toFixed(5)}</p>
                </Box>
                <Box className="flex items-center gap-[.75rem]">
                    <p className="text-[#6B7280]">{t("longitude")}:</p>
                    <p className="font-semibold text-base">{parseFloat(itemDetails.longitude).toFixed(5)}</p>
                </Box>

                <Typography fontWeight={500}
                    sx={{
                        fontSize: "14px",
                        mb: ".5rem",
                        color: itemDetails.status === "Ожидает"
                            ? "#D03801"
                            : itemDetails.status === "В работе"
                                ? "#data"
                                : itemDetails.status === "На проверке"
                                    ? "orange"
                                    : "#1C64F2"
                    }}
                >
                    {itemDetails.status}
                </Typography>
                <Box
                    fontWeight={500}
                    className="flex w-fit gap-[.5rem] items-center mb-[.5rem]"
                >
                    { itemDetails.level }
                    { itemDetails.level === "Маленькая яма"
                        ? <PinGreen />
                        : itemDetails.level === "Средняя яма"
                            ? <PinOrange />
                            : itemDetails.level === "Большая яма"
                                ? <PinRed />
                                : null
                    }
                </Box>
                <Typography sx={{color: "rgba(17, 25, 40, 1)", fontWeight: "500"}}>
                    {dayjs(itemDetails.date).format("DD MMMM YYYY")}
                </Typography>
            </Box>
            { itemDetails.repair_image &&
                <Box sx={{marginTop: "10px"}}>
                    <Typography fontWeight={600} sx={{marginBottom: "10px !important"}}>
                        {t("referred_files")}
                    </Typography>
                    <Box sx={{position: "relative", width: "42px", height: "42px", overflow: "hidden", borderRadius: "4px"}}>
                        <Link to={`https://backend.jol-almaty.kz/${itemDetails.repair_image}`} target="_blank">
                            <img
                                style={{position: "absolute", width: "100%", height: "100%", top: "0", left: "0"}}
                                src={`https://backend.jol-almaty.kz/${itemDetails.repair_image}`}
                                alt="pit"
                            />
                        </Link>
                    </Box>
                </Box>
            }
            { itemDetails.status !== "Исправили" && itemDetails.status !== "На проверке" &&
                <Button
                    sx={{width: "100%", mt: "10px", color: "white", backgroundColor: "brandColor.primary", padding: ".5rem 1rem",
                        ":hover": {
                            color: "white", backgroundColor: "brandColor.primary",
                            opacity: ".75"
                        }
                    }}
                    onClick={() => onFixedHandler(itemDetails.id, itemDetails.status)}
                >
                    {t(getButtonLabel(itemDetails.status))}
                </Button>
            }
            { itemDetails.status === "На проверке" &&
                <Box sx={{display: "flex", gap: ".5rem"}}>
                    <Button
                        sx={{width: "100%", mt: "10px", color: "white", backgroundColor: "brandColor.primary", padding: ".5rem 1rem",
                            ":hover": {
                                color: "white", backgroundColor: "brandColor.primary",
                                opacity: ".75"
                            }
                        }}
                        onClick={() => onFixedHandler(itemDetails.id, "Ожидает")}
                    >
                        {t("decline")}
                    </Button>
                    <Button
                        sx={{width: "100%", mt: "10px", color: "white", backgroundColor: "brandColor.primary", padding: ".5rem 1rem",
                            ":hover": {
                                color: "white", backgroundColor: "brandColor.primary",
                                opacity: ".75"
                            }
                        }}
                        onClick={() => onFixedHandler(itemDetails.id, "Исправили")}
                    >
                        {t("accept")}
                    </Button>
                </Box>
            }
        </>
    )
}

export default ItemDetails;