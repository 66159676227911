import {createBrowserRouter} from "react-router-dom";
import ErrorBoundary from "../layouts/ErrorBoundary";
import SignIn from "../pages/SignIn/SignIn";
import MainLayout from "../layouts/MainLayout";
import RoadPits from "../pages/RoadPits";
import PitsMap from "../pages/PitsMap";
import Statistics from "../pages/Statistics";
import PageInDev from "../components/PageInDev";
import Report from "../pages/Report";
import RoadSignsMap from "../pages/RoadSignsMap";
import Repairers from "../pages/Repairers";
import RepairerForm from "../pages/Repairers/RepairerForm";
import RepairerDetails from "../pages/Repairers/RepairerDetails";
import Tasks from "../pages/Tasks";
import Profile from "../pages/Profile";

const router = createBrowserRouter([
    {
        path: "/",
        element: <MainLayout />,
        // loader: rootLoader,
        errorElement: <ErrorBoundary />,
        children: [
            {
                path: "/",
                element: <Statistics />,
            },
            {
                path: "/pits",
                element: <RoadPits />,
            },
            {
                path: "/tasks",
                element: <Tasks />
            },
            {
                path: "/map",
                element: <PitsMap />
            },
            {
                path: "/road-signs",
                element: <RoadSignsMap />
            },
            {
                path: "/performers",
                element: <Repairers />
            },
            {
                path: "/reports",
                element: <Report />
            },
            {
                path: "/profile",
                element: <Profile />
            }
            // {
            //     path: "/performers/:id",
            //     element: <RepairerDetails />
            // },
            // {
            //     path: "/performers/create",
            //     element: <RepairerDetails />
            // }
        ]
    },
    {
        path: "/auth",
        element: <SignIn />
    }
]);

export default router;