import {isRouteErrorResponse, useRouteError} from "react-router-dom";

const ErrorBoundary = () => {
    const error = useRouteError();
    console.log("ERROR BOUNDARY", error);
    if (isRouteErrorResponse(error)) {
        return (
            <div>
                <h1>Oops!</h1>
                <h2>{error.status}</h2>
                <p>{error.statusText}</p>
                {error.data?.message && <p>{error.data.message}</p>}
            </div>
        );
    }

    return (
        <div>
            <div>Something went wrong!</div>
        </div>
    )
}

export default ErrorBoundary;