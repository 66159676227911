import React, { ReactNode } from "react";
import {TextField, InputAdornment, SxProps, Theme, Box, Typography} from "@mui/material";

type Props = {
    label: string;
    Icon?: ReactNode;
    sx?: SxProps<Theme>;
    disabled?: boolean;
    error?: boolean;
    id?: string;
    type?: string;
    defaultValue?: string | number | boolean | File;
    helperText?: React.ReactNode;
    variant?: "filled" | "outlined" | "standard";
    value?: string;
    multiline?: boolean;
    rows?: number;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    InputProps?: any,
};

const FormTextField = ({ label, sx={}, Icon = (<></>), InputProps={}, ...props }: Props) => {
    const styles = {
        maxWidth: "360px",
        width: "100%",
        backgroundColor: "rgba(249, 250, 251, 1)",
        height: "48px",
        border: "none",
        outline: "none",
        borderRadius: "8px",
        fontSize: "14px",
        fontWeight: "400",
        "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
            "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "2px solid",
                borderColor: "primary.main",
            },
        },
        ...sx,
    };

    const inputProps = {
        ...InputProps,
        endAdornment: Icon ? <InputAdornment position="end">{Icon}</InputAdornment> : undefined,
        sx: { height: "48px", ...InputProps.sx },
    };

    return (
        <Box>
            <Typography sx={{fontWeight: "500", fontSize: "14px", mb: ".5rem"}}>
                {label}
            </Typography>
            <TextField
                color="primary"
                variant="outlined"
                {...props}
                sx={styles}
                InputProps={inputProps}
                FormHelperTextProps={{
                    sx: { marginTop: "0px", lineHeight: "14px", marginBottom: "14px" }
                }}
            />
        </Box>
    )
}



export default FormTextField;
