import React, {useContext, useEffect, useState} from "react";
import {Box, Button, Modal, SwipeableDrawer, Typography} from "@mui/material";
import {DataGrid, GridSingleSelectColDef} from "@mui/x-data-grid";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {Row} from "../../services/common-types";
import {getRepairers} from "./services/fetchers";
import CustomMenuCell from "../../components/CustomMenuCell";
import CustomButton from "../../UI/CustomButton";
import RepairerDetails from "./RepairerDetails";
import {ReactComponent as EditIcon} from "../../assets/icons/pencil.svg";
import {ReactComponent as DeleteIcon} from "../../assets/icons/trash.svg";
import {AuthContext} from "../../context/AuthContext";


const headers = [
    {
        key: "id",
        label: "id"
    },
    {
        key: "name",
        label: "name"
    },
    // {
    //     key: "username",
    //     label: "username"
    // },
    {
        key: "email",
        label: "email"
    },
    {
        key: "region",
        label: "region"
    },
    {
        key: "place",
        label: "place"
    },
    {
        key: "role",
        label: "role"
    }
]

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 416,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: "1.25rem 2rem",
    borderRadius: ".5rem",
    outline: "none",
    maxHeight: "95%",
    height: "fit-content",
    // overflowY: "scroll"
};

const Repairers = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { setNotification } = useContext(AuthContext);
    const [rows, setRows] = useState<Row[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [columns, setColumns] = useState<GridSingleSelectColDef[]>([]);
    const [open, setOpen] = useState(false);
    const [itemId, setItemId] = useState<number | null>(null);
    const isMobile = window.innerWidth < 768;

    const handleSetItemId = (itemId: number) => {
        setItemId(itemId);
        setOpen(true);
    }

    const getItems = async () => {
        try {
            setIsLoading(true);
            const rows = await getRepairers();
            const cols = headers.map((col: any) => ({
                ...col,
                field: col.key,
                headerName: t(col.label),
                flex: col.key === "id" ? 0 : 1,
                width: col.key === "id" ? 50 : 125
            }))
            cols.push({
                field: "actions",
                headerName: t("actions"),
                width: 150,
                // @ts-ignore
                sortable: false, // Отключение сортировки для этой колонки
                filterable: false, // Отключение фильтрации для этой колонки
                disableColumnMenu: true, // Отключение меню колонки
                renderCell: (cellParams: any) => <CustomMenuCell cellParams={cellParams} setItemId={handleSetItemId} />
            })
            setRows(rows);
            // @ts-ignore
            setColumns(cols);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getItems();
    }, [])

    const handleClose = () => {
        setItemId(null);
        setOpen(prev => !prev);
    }

    const handleDelete = async (id: string) => {
        try {
            setIsLoading(true)
            await axios.delete(`/users/repairers/${id}/`);
            setNotification({
                type: "success",
                open: true,
                message: "Delete succeed! WILL RELOAD NOW"
            })
            setIsLoading(false)
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } catch (error) {
            setNotification({
                type: "error",
                open: true,
                message: "Error occurred while delete, try later!"
            })
        }
    }

    return (
        <Box sx={{paddingTop: {xs: "1rem", md: "0"}, borderRadius: ".5rem", background: {xs: "#fff", md: "transparent"}, boxShadow: {xs: "0px 2px 3px 0px rgba(0, 0, 0, 0.1)", md: "none"}}}>
            <Box sx={{paddingX: {xs: "1rem", md: "0"}, mb: "1rem", display: "flex", flexDirection: "column"}}>
                <Typography variant="h1" sx={{display: {xs: "none", md: "block"}, mb: ".75rem", fontSize: {xs: "1.5rem", md: "2rem"}, fontWeight: "700"}}>{t("performers")}</Typography>
                <CustomButton style={{display: "flex", width: {xs: "100%", md: "fit-content"}}} label={t("add_new_repairer")}
                    onChange={() => setOpen(true)} />
            </Box>
            <Box sx={{p: "1.5rem", borderRadius: ".5rem", background: "#fff", boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.1)"}}>
                <Box sx={{mb: "1rem", display: "flex", alignItems: "center", gap: "6px"}}>
                    <Typography variant="h1" sx={{fontSize: {xs: "1rem", md: "1.25rem"}, fontWeight: "700"}}>{t("performers")}</Typography>
                    <Typography component="span" sx={{width: "1.25rem", height: "1.25rem", borderRadius: "50%", backgroundColor: "rgba(225, 239, 254, 1)",
                        display: "flex", alignItems: "center", justifyContent: "center"}}
                    >
                        {rows.length}
                    </Typography>
                </Box>
                { isMobile
                    ? (
                        <Box sx={{display: "flex", flexDirection: "column", gap: "1.5rem"}}>
                            { rows.map((row, index) => (
                                <Box key={index} sx={{display: "flex", gap: "1rem", justifyContent: "space-between"}}>
                                    <Box>
                                        <Typography sx={{fontWeight: "600"}}>
                                            {row.name}
                                        </Typography>
                                        <Typography sx={{fontWeight: "500"}}>
                                            {row.email}
                                        </Typography>
                                    </Box>
                                    <Box sx={{display: "flex", gap: ".25rem"}}>
                                        <Button type="button"
                                            onClick={() => handleSetItemId(row.id)}
                                            sx={{":hover": {opacity: ".8", backgroundColor: "brandColor.primary"},
                                                p: ".5rem 0", width: "40px", height: "40px", minWidth: "fit-content", borderRadius: ".5rem", backgroundColor: "brandColor.primary"}}
                                        >
                                            <EditIcon />
                                        </Button>
                                        <Button type="button"
                                            onClick={() => handleDelete(row.id)}
                                            sx={{":hover": {opacity: ".8", backgroundColor: "red"},
                                                p: ".5rem .75rem", width: "40px", height: "40px", minWidth: "fit-content", borderRadius: ".5rem", backgroundColor: "red"}}
                                        >
                                            <DeleteIcon />
                                        </Button>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    ) : (
                        <DataGrid
                            sx={{
                                zIndex: "1001",
                                border: "none",
                                ".MuiDataGrid-virtualScrollerRenderZone": {
                                    transform: "none !important"
                                },
                                ".MuiDataGrid-cell": {
                                    border: "none",
                                    pl: "0",
                                },
                                ".MuiDataGrid-row": {
                                    gap: ".75rem"
                                },
                                ".css-yrdy0g-MuiDataGrid-columnHeaderRow": {
                                    gap: ".75rem"
                                },
                                ".MuiDataGrid-columnHeader": {
                                    pl: "0"
                                },
                                ".MuiDataGrid-columnHeaders": {
                                    border: "none"
                                },
                                ".MuiDataGrid-footerContainer": {
                                    border: "none"
                                },
                                ".MuiDataGrid-columnHeaderTitle": {
                                    color: "text.secondary"
                                }
                            }}
                            disableVirtualization
                            getRowSpacing={() => ({ top: 12, bottom: 12 })}
                            getRowHeight={() => "auto"}
                            // autoHeight
                            disableColumnMenu
                            rows={rows}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 10 },
                                },
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                    },
                                },
                            }}
                            pageSizeOptions={[10, 25, 50, 100]}
                            loading={isLoading}
                            hideFooterSelectedRowCount
                            componentsProps={{
                                pagination: {
                                    labelRowsPerPage: t("rows_per_page")
                                }
                            }}
                        />
                    )
                }
                { isMobile
                    ? (
                        <SwipeableDrawer
                            sx={{".MuiPaper-root": {borderTopLeftRadius: "8px", borderTopRightRadius: "8px"}}}
                            anchor="bottom"
                            open={open}
                            onClose={handleClose}
                            onOpen={handleClose}
                        >
                            <Box
                                sx={{ width: "auto", p: "1.25rem 1rem"}}
                                role="presentation"
                                // onClick={handleOpenMobileFilters}
                                onKeyDown={handleClose}
                            >
                                <RepairerDetails id={itemId} handleClose={handleClose} />
                            </Box>
                        </SwipeableDrawer>
                    ) : (
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <RepairerDetails id={itemId} handleClose={handleClose} />
                            </Box>
                        </Modal>
                    )
                }
            </Box>
        </Box>
    )
}

export default Repairers;