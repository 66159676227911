import * as React from "react";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import {useContext, useState} from "react";
import {Box, Typography} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {SignInValues} from "./type";
import postSignIn from "./fetchers";
import {AuthContext} from "../../context/AuthContext";
import CustomButton from "../../UI/CustomButton";
import TextLogo from "../../UI/TextLogo";
import FormTextField from "../../UI/InputFields/FormTextField";
import Localization from "../../components/Localization";

const SignIn = () => {
    const { t } = useTranslation();
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            username: "",
            password: "",
        } as SignInValues,
    })
    const [formError, setFormError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const {me, setIsLoggedIn} = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();

    const onSubmit: SubmitHandler<SignInValues> = async data => {
        try {
            setLoading(true);
            await postSignIn(data);
            let pathname = "/";
            if (location.state) {
                pathname = location.state.from.pathname;
            }
            window.location.reload();
        } catch (err) {
            setFormError("Invalid username or password")
        } finally {
            setLoading(false);
        }
        return null;
    }

    if (Object.keys(me).length > 0) {
        return <Navigate to="/" />
    }

    const [showPassword, setShowPassword] = useState(false);
    const handleShowPassword = (event: React.MouseEvent) => {
        setShowPassword(!showPassword);
        event.preventDefault();
        event.stopPropagation();
    }
    const PasswordIcon = showPassword ? VisibilityOffIcon : VisibilityIcon;

    return (
        <Box sx={{ backgroundColor: "customBackground.main", width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", }}>
            <Box sx={{ backgroundColor:"background.paper" , width: "360px", height: "fit-content", display: "flex", alignItems: "center", justifyContent: "center",
                border: "1px solid",
                borderColor: "border.primary",
                padding: "24px",
                borderRadius: "4px"}}
            >
                <form onSubmit={handleSubmit(onSubmit)} style={{width: "100%", maxWidth: "640px"}}>
                    <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", mb: "24px"}}>
                        <TextLogo size="24px"/>
                        <Typography sx={{fontSize: "16px"}}>
                            {t("sign_in")}&nbsp;&nbsp;
                            <Localization />
                        </Typography>
                    </Box>
                    <Typography sx={{color: "red", fontSize: "1rem", mb: "500", textAlign: "center"}}>
                        {formError}
                    </Typography>
                    <Box sx={{display: "flex", flexDirection: "column", width: "100%", gap: "1.5rem"}}>
                        <Controller
                            control={control}
                            rules={{
                                required: true
                            }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <FormTextField
                                    label="Логин"
                                    Icon={<AccountCircle />}
                                    disabled={loading}
                                    error={!!errors.username}
                                    id="standard-error-helper-text"
                                    defaultValue=""
                                    helperText={errors?.username?.message}
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                />
                            )}
                            name="username"
                        />
                        <Controller
                            control={control}
                            rules={{
                                required: true
                            }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <FormTextField
                                    label="Пароль"
                                    Icon={<PasswordIcon sx={{ cursor: "pointer" }} onMouseDown={handleShowPassword} />}
                                    disabled={loading}
                                    type={showPassword ? "text" : "password"}
                                    error={!!errors.password}
                                    id="standard-error-helper-text"
                                    defaultValue=""
                                    helperText={errors?.password?.message}
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                />
                            )}
                            name="password"
                        />
                        <CustomButton
                            mode="main"
                            label={t("voiti")}
                            onChange={() => handleSubmit(onSubmit)()}
                            disabled={loading}
                            style={{ width: "fit-content", alignSelf: "start" }}
                            Icon={<ArrowForwardIosIcon />}
                        />
                    </Box>
                </form>
            </Box>
        </Box>
    )
}

export default SignIn;