import {FormControl, InputLabel, MenuItem, Select, Typography} from "@mui/material"
import React, {FC} from "react";

type Option = string[] | Record<string, any>[]


type SelectFieldType = {
    label: string,
    options: Option,
    value: string,
    onChange: (value: string) => void
}

const SelectField: FC<SelectFieldType> = ({label="", options=[], value="", onChange=(value: string) => {}}) => (
    <FormControl fullWidth sx={{maxWidth: "100%", width: "100%", mb: "1rem"}}>
        <Typography sx={{fontWeight: "500", fontSize: "14px", mb: ".5rem"}}>
            {label}
        </Typography>
        <Select
            sx={{
                borderRadius: "8px",
                fontSize: "14px",
                fontWeight: "400",
                backgroundColor: "rgba(249, 250, 251, 1)",
            }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            // label="Age"
            onChange={e => onChange(e.target.value)}
        >
            { options.map((option: any, index: number) => {
                const label = typeof option === "object" ? option.label : option;
                const value = typeof option === "object" ? option.value : option;
                return (
                    <MenuItem key={index} value={value}>{label}</MenuItem>
                )
            })}
        </Select>
    </FormControl>
)

export default SelectField