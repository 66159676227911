const componentsOptions = {
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    "& .MuiInputBase-root": {
                        height: "48px",
                        "& .MuiInputBase-input": {
                            minHeight: "48px",
                            boxSizing: "border-box",
                        },
                    },
                    "& .MuiInputLabel-root": {
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        top: "-4px"
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    padding: "0",
                },
                "&:hover": {
                    backgroundColor: "none",
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: "0",
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                    "&.Mui-disabled": {
                        backgroundColor: "transparent",
                    },
                },
            },
        },
    },
}
export default componentsOptions;

