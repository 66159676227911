import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

// @ts-ignore
i18n.use(initReactI18next)
    .use(I18nextBrowserLanguageDetector)
    .use(HttpApi)
    .init({
        supportedLngs: ["ru", "kk"],
        fallbackLng: "ru",
        // debug: true,
        detection: {
            order: ["querystring", "cookie", "localStorage", "navigator"],
            caches: ["cookie", "localStorage"],
            lookupQuerystring: "lng",
            lookupCookie: "lng",
            lookupLocalStorage: "lng",
            cookieOptions: { path: "/", sameSite: "strict", secure: false },
        },
        react: {
            useSuspense: true,
            wait: true,
        },
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: "/assets/locales/{{lng}}/translation.json",
        },
    });

export default i18n;
