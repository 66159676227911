import { useTranslation } from "react-i18next";
import { IconButton, Menu, MenuItem } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import React, { useState } from "react";

const Localization = () => {
    const { t, i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const lang = localStorage.getItem("lng");

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeLang = (lang: string) => {
        i18n.changeLanguage(lang).then(() => window.location.reload());
    };
    return (
        <>
            <IconButton onClick={handleClick}>
                <LanguageIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                sx={{
                    "& .MuiList-root": {
                        p: ".0",
                    },
                }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <MenuItem sx={{ background: lang === "kk" ? "var(--button-background-color)" : "initial" }} onClick={() => handleChangeLang("kk")}>
                    KK
                </MenuItem>
                <MenuItem sx={{ background: lang === "ru" ? "var(--button-background-color)" : "initial" }} onClick={() => handleChangeLang("ru")}>
                    RU
                </MenuItem>
            </Menu>
        </>
    );
};

export default Localization;
