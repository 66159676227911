import {FC} from "react";
import FormInputs from "./formInputs";

const Fields: FC<any> = ({formFields={}, errors, control, readOnly, isNotView, formValues}) => {
    const fields = Object.keys(formFields);
    console.log("fields", fields);
    return (
        <>
            { fields.map((name: string, index: number) => (
                <FormInputs
                    puts
                    key={index}
                    field={(formFields as any)[name]}
                    name={name}
                    control={control}
                    readOnly={readOnly}
                    isNotView={isNotView}
                    formValues={formValues}
                    error={errors[name] || {}}
                />
            ))}
        </>
    )
}

export default Fields;