import React, {FC, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Box, IconButton, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import RepairerForm from "./RepairerForm";
import {getRepairer} from "./services/fetchers";
import {ReactComponent as CloseIcon} from "../../assets/icons/close-icon.svg";
import {getRegions} from "../../services/fetchers";

type RepairerDetailsProps = {
    id?: number | null,
    handleClose: () => void,
}

const RepairerDetails: FC<RepairerDetailsProps> = ({id, handleClose}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const isEdit = !!id;

    useEffect(() => {
        if (id) {
            (async () => {
                try {
                    setLoading(true);
                    const data = await getRepairer(id);
                    setData(data);
                } catch (e) {
                    navigate("/performers");
                } finally {
                    setLoading(false);
                }
            })()
        }
    }, [id])

    return (
        <Box>
            <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", mb: "1rem"}}>
                <Typography variant="h1" sx={{fontSize: "1.25rem", fontWeight: "600"}}>
                    { isEdit
                        ? t("edit_repairer")
                        : t("create_repairer")}
                </Typography>
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            { isEdit
                ? !loading &&
                    <RepairerForm data={data} />
                : <RepairerForm />
            }
        </Box>
    )
}

export default RepairerDetails;