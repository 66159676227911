import { ThemeProvider as MuiThemeProvider, createTheme, Shadows } from "@mui/material/styles";
import {FC, ReactElement, useContext, useMemo} from "react";
import {AuthContext} from "./AuthContext";
import {darkThemeOptions, lightThemeOptions} from "../services/themeOptions";
import componentsOptions from "../services/componentsOptions";

type ThemeProviderProps = {
    children: ReactElement[]
}

const ThemeProvider: FC<ThemeProviderProps> = ({children}) => {
    const {mode} = useContext(AuthContext);

    const theme = useMemo(
        () =>
            createTheme({

                palette: {
                    mode,
                    primary: {
                        main: "#1C64F2",
                    },
                    ...(mode === "light" ? lightThemeOptions : darkThemeOptions),
                },
                typography: {
                    button: {
                        textTransform: "none"
                    }
                },
                shadows: Array(25).fill("none") as Shadows,
                ...componentsOptions,
            }),
        [mode],
    );

    return (
        <MuiThemeProvider theme={theme}>
            {children}
        </MuiThemeProvider>
    )
}

export default ThemeProvider;