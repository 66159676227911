import {Box, Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useContext} from "react";
import {AuthContext} from "../../context/AuthContext";


const NoPermission = () => {
    const navigate = useNavigate();
    const {setNoPermission} = useContext(AuthContext);

    const handleGoHome = () => {
        setNoPermission(false);
        navigate("/");
    }

    return (
        <Box>
            403 Вы что то перепутали, так как у вас нет Прав!
            <Button onClick={handleGoHome}>Домой</Button>
        </Box>
    )
}

export default NoPermission;