import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import { DateView, ruRU } from "@mui/x-date-pickers";
import updateLocale from "dayjs/plugin/updateLocale"
import { ReactComponent as DatePickerIcon } from "../../assets/icons/calendar-month.svg";

import("dayjs/locale/ru");

dayjs.locale("ru");

dayjs.extend(updateLocale)
dayjs.updateLocale("ru", {
    weekStart: 1,
})

type Props = {
    views?: DateView[] | null;
    readOnly?: boolean;
    style?: React.CSSProperties;
    label?: string;
    value: Dayjs | string | null | "";
    onChange: (value: Dayjs | null) => void;
    disableBorders?: boolean;
    openTo?: DateView | undefined;
    from?: boolean;
    to?: boolean;
};

const DatePickerCustom = ({ from=false, to=false, openTo = undefined, views = null, label = "", value = null, onChange, style = {}, readOnly = false, disableBorders = false }: Props) => {
    const date = value ? typeof value === "string" ? dayjs(value) : value : dayjs();
    const defaultStyles = {
        maxWidth: {xs: "100%", md: "200px"},
        width: "100%",
        overflow: "initial",
        backgroundColor: "customBackground.secondary",
        height: "42px",
        ".MuiOutlinedInput-input": {
            fontSize: "14px"
        },
        "& .MuiOutlinedInput-root": {
            height: "42px !important",
            ".MuiOutlinedInput-notchedOutline": {
                border: "1px solid #D1D5DB",
                borderRadius: ".5rem",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "2px solid",
                borderColor: "primary.main",
            },
            ".MuiIconButton-root": {
                marginRight: "0px"
            }
        },
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
                components={["DatePicker"]}
                sx={{
                    ...defaultStyles,
                    ...style,
                    paddingTop: "-8px"
                }}
            >
                <DatePicker
                    {...(openTo
                        ? {
                            openTo,
                        }
                        : {})}
                    {...(views
                        ? {
                            views,
                        }
                        : {})}
                    readOnly={readOnly}
                    value={date}
                    sx={{
                        backgroundColor: "#F9FAFB",
                        width: {xs: "100%", md: "200px"},
                        minWidth: "auto !important",
                    }}
                    // label="Дата"
                    slotProps={{
                        layout: {
                            sx: {
                                mt: "4px",
                                marginLeft: "-2px",
                                backgroundColor: "background.paper",
                            }
                        }
                    }}
                    slots={{
                        openPickerIcon: DatePickerIcon,
                    }}
                    format={`${from ? "От " : ""}${to ? "До " : ""}DD MMMM YYYY`}
                    onChange={onChange}
                    dayOfWeekFormatter={dayOfWeek => {
                        const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
                        return days[dayOfWeek as any];
                    }}
                />
            </DemoContainer>
        </LocalizationProvider>
    );
};

export default DatePickerCustom;
