import React, { FC } from "react"
import { GridRenderCellParams } from "@mui/x-data-grid/models/params/gridCellParams"
import {Box, Typography} from "@mui/material"
import ModalImage from "react-modal-image";
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";
import {ReactComponent as PinOrange} from "../../assets/icons/pin-orange.svg";
import {ReactComponent as PinGreen} from "../../assets/icons/pin-green.svg";
import {ReactComponent as PinRed} from "../../assets/icons/pin-red.svg";

import("dayjs/locale/ru");

dayjs.locale("ru");

type Props = {
    columnId: string
    cellParams: GridRenderCellParams
}

export const RenderCell: FC<Props> = ({ columnId="", cellParams: { row } }) => {
    const {t} = useTranslation();
    const cellValue = row[columnId]

    switch (columnId) {
        case "image":
            return (
                <Box sx={{width: "80px", height: "80px"}}>
                    <ModalImage
                        className="w-[80px] h-[80px] rounded"
                        large={cellValue}
                        medium={cellValue}
                        small={cellValue}
                        alt=''
                    />
                </Box>
            )
        case "date":
            return (
                <Box fontWeight={500}>{dayjs(cellValue).format("DD MMMM YYYY")}</Box>
            )
        case "coordinates":
            return (
                <Box className="flex items-center flex-col">
                    <Box className="flex gap-[.5rem]">
                        <Typography color="text.secondary" className="!text-[14px]">{t("latitude")}:</Typography>
                        <Typography className="!font-semibold">{row.latitude}</Typography>
                    </Box>
                    <Box className="flex gap-[.5rem]">
                        <Typography color="text.secondary" className="!text-[14px]">{t("longitude")}:</Typography>
                        <Typography className="!font-semibold">{row.longitude}</Typography>
                    </Box>
                </Box>
            )
        case "status":
            return (
                <Box
                    title={cellValue}
                    className="MuiDataGrid-cellContent"
                >
                    { row.repaired_by &&
                        <Typography fontWeight={600}>{row.repaired_by}</Typography>
                    }
                    <Typography fontWeight={500}
                        sx={{
                            color: row.status === "Ожидает"
                                ? "#D03801"
                                : row.status === "В работе"
                                    ? "#047481"
                                    : row.status === "На проверке"
                                        ? "orange"
                                        : "#1C64F2"
                        }}
                    >
                        {row.status}
                    </Typography>
                </Box>
            )
        case "level":
            return (
                <Box
                    fontWeight={500}
                    title={cellValue}
                    className="flex w-fit gap-[.5rem] items-center"
                >
                    { cellValue }
                    { cellValue === "Маленькая яма"
                        ? <PinGreen />
                        : cellValue === "Средняя яма"
                            ? <PinOrange />
                            : cellValue === "Большая яма"
                                ? <PinRed />
                                : null
                    }
                </Box>
            )
        default:
            return (
                <Box
                    fontWeight={500}
                    title={cellValue}
                    className="MuiDataGrid-cellContent"
                >
                    { cellValue }
                </Box>
            )

    }
}

export default RenderCell;