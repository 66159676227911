import React, {useEffect, useRef, useState} from "react";
import ApexCharts from "apexcharts";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import {BASE_URL} from "../../../services/constants";
import {getLineChartOptions} from "../configs";
import PeriodChangeMenu from "./PeriodChangeMenu";

type ChartType = {
    chart: {
        data?: Array<number>,
        categories?: Array<string>
    },
    total: number | null
}

const LineChart = ({
    name="line",
    isNewDefects=true
}) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [firstChart, setFirstChart] = useState<ChartType>({
        chart: {},
        total: null
    });
    const chartInstance = useRef<any>(null);
    const [period, setPeriod] = useState(dayjs().add(-30, "day").format("YYYY-MM-DD"));

    useEffect(() => {
        const getNewDefectsChartOptions = async () => {
            try {
                setIsLoading(true);
                const res = await axios.get(
                    `${BASE_URL}/detection/dashboard/${isNewDefects ? "new_defects" : "repaired_defects"}/`,
                    {params: {date_after: period, date_before: dayjs().format("YYYY-MM-DD")}}
                );
                setFirstChart(res.data);
            } catch (e) {
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        }
        getNewDefectsChartOptions();
    }, [period])

    useEffect(() => {
        if (Object.keys(firstChart.chart).length === 2 && document.getElementById(`area-chart-${isNewDefects ? "new" : "repaired"}`) && typeof ApexCharts !== "undefined") {
            if (chartInstance.current) {
                chartInstance.current.updateSeries([{
                    data: firstChart.chart.data as Array<number>
                }]);
            } else {
                const options = getLineChartOptions(firstChart.chart.data, firstChart.chart.categories, t(isNewDefects ? "new_defects" : "repaired_defects"));
                chartInstance.current = new ApexCharts(document.getElementById(`area-chart-${isNewDefects ? "new" : "repaired"}`), options);
                chartInstance.current.render();
            }
        }
    }, [firstChart])

    // if (!isLoading && firstChart.total === 0) {
    //     return null;
    // }

    return (
        <div className="w-full bg-white rounded-lg shadow p-4 md:p-6">
            <div className="flex justify-between">
                <div>
                    <h5 className="leading-none text-3xl font-bold text-gray-900 dark:text-white pb-2">{firstChart.total}</h5>
                    <p className="text-base font-normal text-gray-500 dark:text-gray-400">{t(isNewDefects ? "new_defects" : "repaired_defects")}</p>
                </div>
                {/*<div*/}
                {/*    className="flex items-center px-2.5 py-0.5 text-base font-semibold text-green-500 dark:text-green-500 text-center">*/}
                {/*    12%*/}
                {/*    <svg className="w-3 h-3 ms-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 14">*/}
                {/*        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13V1m0 0L1 5m4-4 4 4"/>*/}
                {/*    </svg>*/}
                {/*</div>*/}
            </div>
            <div id={`area-chart-${isNewDefects ? "new" : "repaired"}`}></div>
            <div className="grid grid-cols-1 items-center border-gray-200 border-t dark:border-gray-700 justify-between">
                <div className="flex justify-between items-center pt-5">
                    <PeriodChangeMenu
                        name={name}
                        period={period}
                        setPeriod={setPeriod}
                    />
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link
                        to={isNewDefects ? "/pits?mode=Список" : "/reports"}
                        className="uppercase text-sm font-semibold inline-flex items-center rounded-lg text-blue-600
                        hover:text-blue-700 dark:hover:text-blue-500  hover:bg-gray-100 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 px-3 py-2"
                    >
                        {t(isNewDefects ? "list" : "reports")}
                        <svg className="w-2.5 h-2.5 ms-1.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
                        </svg>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default LineChart;