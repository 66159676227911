import axios from "axios";

export const getRepairers = async () => {
    try {
        const res = await axios.get("/users/repairers/");
        return await res.data;
    } catch (err) {
        throw new Error("Error getRelatedSearchesTable");
    }
}

export const getRepairer = async (id: number) => {
    try {
        const res = await axios.get(`/users/repairers/${id}/`);
        return await res.data;
    } catch (err) {
        throw new Error("Error getRelatedSearchesTable");
    }
}

export const createRepairer = async (data: any) => {
    try {
        const res = await axios.post("/users/repairers/", data);
        return await res.data;
    } catch (err) {
        throw new Error("Error getRelatedSearchesTable");
    }
}

export const editRepairer = async (data: any) => {
    try {
        const res = await axios.put(`/users/repairers/${data.id}/`, data);
        return await res.data;
    } catch (err) {
        throw new Error("Error getRelatedSearchesTable");
    }
}

export const changePitStatus = async (id: number, status: string) => {
    try {
        const res = await axios.post(`/detection/potholes/${id}/change_status/`, {id, status});
        return await res.data;
    } catch (err) {
        throw new Error("Error getRelatedSearchesTable");
    }
}



