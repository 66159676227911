import CssBaseline from "@mui/material/CssBaseline";
import {FC, ReactElement} from "react";
import AuthProvider from "../context/AuthContext";
import ThemeProvider from "../context/ThemeProvider";

type RootLayoutProps = {
    children: ReactElement
}

const RootLayout: FC<RootLayoutProps> = ({children}) => (
    <AuthProvider>
        <ThemeProvider>
            <CssBaseline />
            {children}
        </ThemeProvider>
    </AuthProvider>
)


export default RootLayout;