import React, { ReactElement } from "react";
import { Button } from "@mui/material";

interface CSSPropertiesForMUI extends React.CSSProperties {
    ":hover"?: Record<string, any>;
}

type Props = {
    label: string;
    Icon?: ReactElement | null;
    leftIcon?: ReactElement | null;
    onChange: (value: any) => void;
    style?: any;
    mode?: string;
    disabled?: boolean;
};
type Modes = {
    [key: string]: Object;
};
const CustomButton = ({ leftIcon = null, label = "", Icon = null, onChange, style = {}, mode = "main", disabled = false }: Props) => {
    const modes: Modes = {
        main: {
            backgroundColor: "brandColor.primary",
            color: "text.inverse",
            "&:hover": {
                backgroundColor: "brandColor.primary",
                color: "text.inverse",
            },
        },
        transparent: {
            backgroundColor: "brandColor.primary",
            color: "text.inverse",
            "&:hover": {
                backgroundColor: "brandColor.primary",
                color: "text.inverse",
            },
        },
        green: {
            backgroundColor: "brandColor.primary",
            color: "text.inverse",
            "&:hover": {
                backgroundColor: "brandColor.primary",
                color: "text.inverse",
            },
        },
    };
    return (
        <Button
            onClick={onChange}
            sx={{
                display: "flex",
                p: "8px 22px 8px 19px",
                height: "42px",
                borderRadius: "8px",
                fontSize: "1rem",
                fontWeight: 500,
                gap: "0",
                ...modes[mode],
                ...style,
            }}
            startIcon={leftIcon}
            endIcon={Icon}
            disabled={disabled}
        >
            {label}
        </Button>
    );
};

export default CustomButton;
