export default {
    "first_name": {
        "title": "first_name",
        "hint": "first_name",
        "defaultValue": "",
        "order": 1,
        "rules": {
            "required": {
                "value": true,
                "message": {
                    "kk": "Бұл міндетті өріс!",
                    "ru": "Это обязательное поле!"
                }
            }
        },
        "type": "string",
        "typeSend": "string",
        "category": "general",
        "width": "calc(50% - .5rem)"
    },
    "last_name": {
        "title": "last_name",
        "hint": "last_name",
        "defaultValue": "",
        "order": 1,
        "rules": {
            "required": {
                "value": true,
                "message": {
                    "kk": "Бұл міндетті өріс!",
                    "ru": "Это обязательное поле!"
                }
            }
        },
        "type": "string",
        "typeSend": "string",
        "category": "general",
        "width": "calc(50% - .5rem)"
    },
    "middle_name": {
        "title": "middle_name",
        "hint": "middle_name",
        "defaultValue": "",
        "order": 1,
        "rules": {
            "required": {
                "value": false,
                "message": {
                    "kk": "Бұл міндетті өріс!",
                    "ru": "Это обязательное поле!"
                }
            }
        },
        "type": "string",
        "typeSend": "string",
        "category": "general",
        "width": "calc(50% - .5rem)"
    },
    "username": {
        "title": "username",
        "hint": "username",
        "defaultValue": "",
        "order": 1,
        "rules": {
            "required": {
                "value": false,
                "message": {
                    "kk": "Бұл міндетті өріс!",
                    "ru": "Это обязательное поле!"
                }
            }
        },
        "type": "string",
        "typeSend": "string",
        "category": "general",
        "width": "calc(50% - .5rem)"
    },
    "email": {
        "title": "email",
        "hint": "email",
        "defaultValue": "",
        "order": 1,
        "rules": {
            "required": {
                "value": true,
                "message": {
                    "kk": "Бұл міндетті өріс!",
                    "ru": "Это обязательное поле!"
                }
            }
        },
        "type": "string",
        "typeSend": "string",
        "category": "general",
        "width": "calc(50% - .5rem)"
    },
    "position": {
        "title": "position",
        "hint": "position",
        "defaultValue": "",
        "order": 1,
        "rules": {
            "required": {
                "value": true,
                "message": {
                    "kk": "Бұл міндетті өріс!",
                    "ru": "Это обязательное поле!"
                }
            }
        },
        "type": "string",
        "typeSend": "string",
        "category": "general",
        "width": "calc(50% - .5rem)"
    },
    "region": {
        "title": "region",
        "hint": "region",
        "defaultValue": "Бостандыкский",
        "order": 1,
        "rules": {
            "required": {
                "value": true,
                "message": {
                    "kk": "Бұл міндетті өріс!",
                    "ru": "Это обязательное поле!"
                }
            }
        },
        "options":     [
            "Бостандыкский",
            "Алмалинский",
            "Ауэзовский",
            "Алатауский",
            "Жетысуский",
            "Медеуский",
            "Наурызбайский",
            "Турксибский"
        ],
        "type": "select",
        "typeSend": "string",
        "category": "general",
        "width": "calc(50% - .5rem)"
    },
    "place": {
        "title": "place",
        "hint": "place",
        "defaultValue": "Районный акимат",
        "order": 1,
        "rules": {
            "required": {
                "value": true,
                "message": {
                    "kk": "Бұл міндетті өріс!",
                    "ru": "Это обязательное поле!"
                }
            }
        },
        "options": ["Районный акимат", "Управление"],
        "type": "select",
        "typeSend": "string",
        "category": "general",
        "width": "calc(50% - .5rem)"
    },
    "is_repairer": {
        "title": "is_repairer",
        "hint": "is_repairer",
        "defaultValue": false,
        "order": 3,
        "rules": {
            "required": {
                "value": false,
                "message": {
                    "kk": "Бұл міндетті өріс!",
                    "ru": "Это обязательное поле!"
                }
            }
        },
        "type": "checkbox",
        "typeSend": "boolean",
        "category": "general",
        "width": "calc(50% - .5rem)",
    },
    "is_moderator": {
        "title": "is_moderator",
        "hint": "is_moderator",
        "defaultValue": false,
        "order": 3,
        "rules": {
            "required": {
                "value": false,
                "message": {
                    "kk": "Бұл міндетті өріс!",
                    "ru": "Это обязательное поле!"
                }
            }
        },
        "type": "checkbox",
        "typeSend": "boolean",
        "category": "general",
        "width": "calc(50% - .5rem)",
    }
}
