import React from "react";
import { Typography } from "@mui/material";

type Props = {
   size: string;
}
const TextLogo: React.FC<Props> = ({size}) => {
    const styles = {
        fontSize: size,
        fontWeight: 500,
        lineHeight: "28px"
    };
    return (
        <Typography color="brandColor" sx={styles}>
            Jol<Typography color="brandColor" component="span" sx={styles}>Qalai</Typography>
        </Typography>
    );
};

export default TextLogo;
