import React from "react";
import ReactDOM from "react-dom/client";
import axios from "axios";
import App from "./App";
import {BASE_URL} from "./services/constants";

import "./i18n";

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
    <App />
);
