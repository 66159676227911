import { Box, Link as MuiLink, Typography } from "@mui/material";
import {useContext} from "react";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";

const Breadcrumbs = () => {
    const navigate = useNavigate();
    const {breadcrumbs} = useContext(AuthContext);

    const handleGo = (url: string) => {
        navigate(url);
    }

    if (breadcrumbs.length === 0) {
        return null;
    }

    return (
        <Box sx={{display: "flex", gap: ".5rem", color: "text.disabled", mb: "1.5rem"}}>
            { breadcrumbs.map((breadcrumb, index) => {
                if (index === breadcrumbs.length - 1) {
                    return (
                        <Typography color="text.secondary">
                            {breadcrumb.label}
                        </Typography>
                    )
                }
                return (
                    <>
                        <MuiLink underline="none" color="text.disabled" onClick={() => handleGo(breadcrumb.href)} component="button">{breadcrumb.label}</MuiLink>
                        /
                    </>
                )
            })}
        </Box>
    )
}

export default Breadcrumbs;