import axios from "axios";
import {FilterType} from "../../../hooks/useFilter";

export const getRoadPits = async (filters: FilterType, type="road_pits", isMobile=false) => {
    try {
        const res = await axios.get(`/detection/potholes/${type === "path_signs" ? "map_znaki/" : "map/"}${isMobile ? "?is_mobile=true" : ""}`, {params: filters});
        return await res.data;
    } catch (err) {
        throw new Error("Error getRelatedSearchesTable");
    }
}

export const getMapPits = async (filters={}) => {
    try {
        const query = new URLSearchParams(filters);
        const res = await axios.get(`/detection/potholes/map/?${query}`);
        return await res.data;
    } catch (err) {
        throw new Error("Error getRelatedSearchesTable");
    }
}

export const getMapRoadSigns = async (filters={}) => {
    try {
        const query = new URLSearchParams(filters);
        const res = await axios.get(`/detection/potholes/map_znaki/?${query}`);
        return await res.data;
    } catch (err) {
        throw new Error("Error getRelatedSearchesTable");
    }
}

export const changePitStatus = async (id: number, status: string, file: File | null = null) => {
    try {
        const res = await axios.post(`/detection/potholes/${id}/change_status/`, {id, status, repair_image: file}, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return await res.data;
    } catch (err) {
        throw new Error("Error getRelatedSearchesTable");
    }
}

export const getStatistics = async () => {
    try {
        const res = await axios.get("/detection/statistics/");
        return await res.data;
    } catch (err) {
        throw new Error("Error getRelatedSearchesTable");
    }
}


