import {useNavigate} from "react-router-dom";
import React, {useContext} from "react";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {Box, Button, Menu, MenuItem} from "@mui/material";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {AuthContext} from "../../context/AuthContext";
import {ReactComponent as EditIcon} from "../../assets/icons/pencil.svg";
import {ReactComponent as DeleteIcon} from "../../assets/icons/trash.svg";

const initialCustomMenuCell = {
    actions: {
        view: false,
        edit: true,
        delete: true,
        copy: false
    },
    cellParams: {},
    setIsLoading: (value: boolean) => {},
    setItemId: (id: number) => {},
}

const Index = ({setItemId=initialCustomMenuCell.setItemId, actions=initialCustomMenuCell.actions, cellParams=initialCustomMenuCell.cellParams,
    setIsLoading=initialCustomMenuCell.setIsLoading}: any) =>
{
    const { t } = useTranslation();
    const {setNotification} = useContext(AuthContext);
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const postAction = async (key: string, id: string) => {
        try {
            setIsLoading(true)
            await axios.delete(`/users/repairers/${id}/`);
            setNotification({
                type: "success",
                open: true,
                message: `${key} succeed! WILL RELOAD NOW`
            })
            setIsLoading(false)
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } catch (error) {
            setNotification({
                type: "error",
                open: true,
                message: `Error occurred while ${key}, try later!`
            })
        }
    }

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpen = () => {
        navigate(`${cellParams.id}`);
        handleClose();
    };

    const handleEdit = () => {
        setItemId(cellParams.id);
        // navigate(`${cellParams.id}`);
        // handleClose();
    };

    const handleDelete = async () => {
        handleClose();
        await postAction("delete", cellParams.id);
    }

    const handleCopy = async () => {
        handleClose();
        await postAction("copy", cellParams.id);
    }

    return (
        <Box sx={{display: "flex", gap: ".25rem"}}>
            <Button type="button"
                onClick={handleEdit}
                sx={{":hover": {opacity: ".8", backgroundColor: "brandColor.primary"},
                    p: ".5rem 0", width: "40px", minWidth: "fit-content", borderRadius: ".5rem", backgroundColor: "brandColor.primary"}}
            >
                <EditIcon />
            </Button>
            <Button type="button"
                onClick={handleDelete}
                sx={{":hover": {opacity: ".8", backgroundColor: "red"},
                    p: ".5rem .75rem", width: "40px", minWidth: "fit-content", borderRadius: ".5rem", backgroundColor: "red"}}
            >
                <DeleteIcon />
            </Button>
            {/*<IconButton onClick={handleClick}>*/}
            {/*    <MoreVertIcon />*/}
            {/*</IconButton>*/}
            {/*<Menu*/}
            {/*    anchorEl={anchorEl}*/}
            {/*    open={Boolean(anchorEl)}*/}
            {/*    onClose={handleClose}*/}
            {/*>*/}
            {/*    /!*{ actions.view &&*!/*/}
            {/*    /!*    <MenuItem onClick={handleOpen}>View</MenuItem>*!/*/}
            {/*    /!*}*!/*/}
            {/*    { actions.edit &&*/}
            {/*        <MenuItem onClick={handleEdit}>{t("edit")}</MenuItem>*/}
            {/*    }*/}
            {/*    { actions.delete &&*/}
            {/*        <MenuItem onClick={handleDelete}>{t("delete")}</MenuItem>*/}
            {/*    }*/}
            {/*    /!*{ actions.copy &&*!/*/}
            {/*    /!*    <MenuItem onClick={handleCopy}>Copy</MenuItem>*!/*/}
            {/*    /!*}*!/*/}
            {/*</Menu>*/}
        </Box>
    );

    // Old
    // return (
    //     <div>
    //         <IconButton onClick={handleClick}>
    //             <MoreVertIcon />
    //         </IconButton>
    //         <Menu
    //             anchorEl={anchorEl}
    //             open={Boolean(anchorEl)}
    //             onClose={handleClose}
    //         >
    //             {/*{ actions.view &&*/}
    //             {/*    <MenuItem onClick={handleOpen}>View</MenuItem>*/}
    //             {/*}*/}
    //             { actions.edit &&
    //                 <MenuItem onClick={handleEdit}>{t("edit")}</MenuItem>
    //             }
    //             { actions.delete &&
    //                 <MenuItem onClick={handleDelete}>{t("delete")}</MenuItem>
    //             }
    //             {/*{ actions.copy &&*/}
    //             {/*    <MenuItem onClick={handleCopy}>Copy</MenuItem>*/}
    //             {/*}*/}
    //         </Menu>
    //     </div>
    // );
};

export default Index;
