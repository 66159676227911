import React, {useEffect, useMemo, useState} from "react";
import {Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {DataGrid, useGridApiRef} from "@mui/x-data-grid";
import dayjs from "dayjs";
import {Row} from "../../services/common-types";
import {getReport} from "./fetchers";
import Filters from "../../components/Filters";
import useFilter from "../../hooks/useFilter";
import CustomButton from "../../UI/CustomButton";


const genHeaders = [
    {
        key: "region",
        label: "region"
    },
    {
        key: "name",
        label: "name"
    },
]

const pitsHeaders = [
    {
        key: "all_potholes",
        label: "all_potholes"
    },
    {
        key: "new_potholes",
        label: "new_potholes"
    },
    {
        key: "inwork_potholes",
        label: "inwork_potholes"
    },
    {
        key: "repaired_potholes",
        label: "repaired_potholes"
    }
]

const signsHeaders = [
    {
        key: "all_znak",
        label: "all_znak"
    },
    {
        key: "new_znak",
        label: "new_znak"
    },
    {
        key: "inwork_znak",
        label: "inwork_znak"
    },
    {
        key: "repaired_znak",
        label: "repaired_znak"
    }
]

const Report = () => {
    const { t } = useTranslation();
    const [rows, setRows] = useState<Row[]>([]);
    const [stats, setStats] = useState({
        avg_count_repaired_pits: null,
        avg_count_repaired_signs: null
    });
    const [isLoading, setIsLoading] = useState(false);
    const { filters, pitsSignsItems, handleSelectChange, handleCheckboxChange, handleChangeMobile, statuses, levels, regions } = useFilter();
    const tableRef = useGridApiRef();

    const handleExportUnfilteredCSV = ({tableName=""}) => {
        tableRef.current.exportDataAsCsv({
            fileName: tableName,
            getRowsToExport: (params: any) => params.apiRef.current.getAllRowIds()
        });
    };


    const getItems = async () => {
        try {
            setIsLoading(true);
            const data = await getReport(filters);
            setRows(data.rows);
            setStats(data.cards);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }
    
    useEffect(() => {
        getItems();
    }, [filters])

    const columns: any = useMemo(() => {
        let cols: any[] = [];
        if (filters.type?.includes("road_pits") && filters.type?.includes("path_signs")) {
            cols = [...pitsHeaders, ...signsHeaders];
        } else if (filters.type?.includes("road_pits")) {
            cols = pitsHeaders;
        } else if (filters.type?.includes("path_signs")) {
            cols = signsHeaders
        }
        cols = [...genHeaders, ...cols]
        cols = cols.map((col: any) => ({
            ...col,
            field: col.key,
            headerName: t(col.label),
            flex: col.key === "id" ? 0 : 1,
            width: col.key === "id" ? 50 : 125,
            minWidth: 150
        }))
        return cols;
    }, [filters.type])

    return (
        <Box sx={{marginBottom: "1rem", p: "1.5rem 1.5rem 1.5rem 1.5rem", borderRadius: ".5rem", background: "#fff", boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.1)"}}>
            <Box sx={{display: {xs: "none", md: "flex"}, justifyContent: "space-between", alignItems: "center", mb: {xs: "1rem", md: "2rem"}}}>
                <Typography variant="h1" sx={{fontSize: {xs: "1.5rem", md: "2rem"}, fontWeight: "700"}}>{t("reports")}</Typography>
            </Box>
            <Box>
                <Filters isTabs={false} exclude={["status", "region", "pits_signs"]} filters={filters} regions={regions} statuses={statuses} levels={levels}
                    pitsSignsItems={pitsSignsItems} handleSelectChange={handleSelectChange} handleCheckboxChange={handleCheckboxChange} handleChangeMobile={handleChangeMobile}
                />
                <Box sx={{mt: {xs: "1rem", md: "1.25rem"}, mb: "1rem", display: "flex", flexDirection: {xs: "column", md: "row"}, gap: ".75rem", alignItems: {xs: "start", md: "center"}}}>
                    <Typography sx={{fontWeight: "700", fontSize: "1.25rem", textAlign: "left"}}>
                        {t("report")} {filters.date_before ? dayjs(filters.date_before).format("DD MMMM YYYY") : dayjs().format("DD MMMM YYYY")}
                    </Typography>
                    <CustomButton style={{display: "flex", width: {xs: "100%", md: "fit-content"}}} label={t("download_csv")}
                        onChange={() => handleExportUnfilteredCSV({tableName: "Отчет"})}
                    />
                </Box>
                { stats.avg_count_repaired_signs && stats.avg_count_repaired_pits ?
                    <Box sx={{display: "flex", gap: "1rem", mb: "1.5rem", flexDirection: {xs: "column", md: "row"}}}>
                        { stats.avg_count_repaired_pits ?
                            <Box sx={{gap: ".5rem", p: ".75rem 1.75rem", borderRadius: ".75rem", backgroundColor: "#EDFAFA",
                                display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}
                            >
                                <Typography sx={{color: "#047481", textAlign: "center"}}>
                                    {t("avg_count_repaired_pits")}
                                </Typography>
                                <Typography sx={{color: "#047481", p: "9px 12px", backgroundColor: "#D5F5F6", fontWeight: "500", borderRadius: "30%"}}>
                                    {stats.avg_count_repaired_pits}
                                </Typography>
                            </Box> : null
                        }
                        { stats.avg_count_repaired_signs ?
                            <Box sx={{gap: ".5rem", p: ".75rem 1.75rem", borderRadius: ".75rem", backgroundColor: "#EBF5FF",
                                display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}
                            >
                                <Typography sx={{color: "#1C64F2", textAlign: "center"}}>
                                    {t("avg_count_repaired_signs")}
                                </Typography>
                                <Typography sx={{color: "#1C64F2", p: "9px 12px", backgroundColor: "#E1EFFE", fontWeight: "500", borderRadius: "30%"}}>
                                    {stats.avg_count_repaired_signs}
                                </Typography>
                            </Box> : null
                        }
                    </Box> : null
                }
            </Box>
            <DataGrid
                apiRef={tableRef}
                sx={{
                    zIndex: "1001",
                    border: "none",
                    ".MuiDataGrid-virtualScrollerRenderZone": {
                        transform: "none !important"
                    },
                    ".MuiDataGrid-cell": {
                        border: "none",
                        pl: "0",
                    },
                    ".MuiDataGrid-row": {
                        gap: ".75rem"
                    },
                    ".css-yrdy0g-MuiDataGrid-columnHeaderRow": {
                        gap: ".75rem"
                    },
                    ".MuiDataGrid-columnHeader": {
                        pl: "0"
                    },
                    ".MuiDataGrid-columnHeaders": {
                        border: "none"
                    },
                    ".MuiDataGrid-footerContainer": {
                        border: "none"
                    },
                    ".MuiDataGrid-columnHeaderTitle": {
                        color: "text.secondary"
                    }
                }}
                disableVirtualization
                getRowSpacing={() => ({ top: 12, bottom: 12 })}
                getRowHeight={() => "auto"}
                // autoHeight
                disableColumnMenu
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        },
                    },
                }}
                pageSizeOptions={[10, 25, 50, 100]}
                loading={isLoading}
                hideFooterSelectedRowCount
                componentsProps={{
                    pagination: {
                        labelRowsPerPage: t("rows_per_page")
                    }
                }}
            />
        </Box>
    )
}

export default Report;