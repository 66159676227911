import React, {useContext} from "react";
import {Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {AuthContext} from "../../context/AuthContext";

const Profile = () => {
    const { me } = useContext(AuthContext);
    const { t } = useTranslation();
    return (
        <Box>
            <Box sx={{display: {xs: "none", md: "flex"}, mb: {xs: "1rem", md: "2rem"}}}>
                <Typography variant="h1" sx={{fontSize: {xs: "1.5rem", md: "2rem"}, fontWeight: "700"}}>{t("profile")}</Typography>
            </Box>
            <Box sx={{width: {xs: "100%", md: "fit-content"}, marginBottom: "1rem", p: "1.25rem 2rem 2rem 2rem",
                borderRadius: ".5rem", background: "#fff", boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.1)"}}
            >
                <Typography sx={{fontSize: "1.25rem", fontWeight: "600", mb: "1.5rem"}}>{t("your_data")}</Typography>
                {me.name &&
                    <>
                        <Typography sx={{fontWeight: "500", mb: ".25rem"}}>{t("full_name")}</Typography>
                        <Typography sx={{fontWeight: "600", fontSize: "1rem", mb: "1rem"}}>{me.name}</Typography>
                    </>
                }
                { me.email &&
                    <Box>
                        <Typography sx={{fontWeight: "500", mb: ".25rem"}}>{t("email")}</Typography>
                        <Typography sx={{fontWeight: "600", fontSize: "1rem", mb: "1rem"}}>{me.email}</Typography>
                    </Box>
                }
                { me.region &&
                    <Box>
                        <Typography sx={{fontWeight: "500", mb: ".25rem"}}>{t("region")}</Typography>
                        <Typography sx={{fontWeight: "600", fontSize: "1rem", mb: "1rem"}}>{me.region}</Typography>
                    </Box>
                }
                { me.place &&
                    <Box>
                        <Typography sx={{fontWeight: "500", mb: ".25rem"}}>{t("place")}</Typography>
                        <Typography sx={{fontWeight: "600", fontSize: "1rem", mb: "1rem"}}>{me.place}</Typography>
                    </Box>
                }
                { me.role &&
                    <Box>
                        <Typography sx={{fontWeight: "500", mb: ".25rem"}}>{t("role")}</Typography>
                        <Typography sx={{fontWeight: "600", fontSize: "1rem", mb: "1rem"}}>{me.role}</Typography>
                    </Box>
                }
            </Box>
        </Box>
    )
}

export default Profile;