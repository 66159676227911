import {useNavigate} from "react-router-dom";
import {useContext} from "react";
import axios from "axios";
import DrawerDesktop from "../Drawers/DrawerDesktop";
import DrawerMobile from "../Drawers/DrawerMobile";
import {AuthContext} from "../../context/AuthContext";

const Header = () => {
    const navigate = useNavigate();
    const {setIsLoggedIn, setMe} = useContext(AuthContext);

    const logout = async () => {
        try {
            await axios.post("/users/logout/");
            console.log("logged out")
            navigate("/auth");
            setIsLoggedIn(false);
            setMe({});
        } catch (err) {
            console.log(err);
        }
    }
    return (
        <>
            <DrawerDesktop logout={logout} />
            <DrawerMobile logout={logout} />
        </>
    )
}

export default Header;