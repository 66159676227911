import React, { FC } from "react";
import {
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@mui/material";

type CheckBoxFieldProps = {
    label: string;
    value: boolean;
    onChange: (value: boolean) => void;
    readOnly?: boolean;
};

const CheckBoxField: FC<CheckBoxFieldProps> = ({label, value, onChange, readOnly = false,}) => (
    <FormControl sx={{ width: "100%" }}>
        <FormLabel id={`${label}-radio-buttons-label`}  sx={{ "&.Mui-focused": { color: "text.primary" }, color: "text.primary", fontWeight: "500" }}>
            {label}
        </FormLabel>
        <RadioGroup
            row
            aria-labelledby={`${label}-radio-buttons-label`}
            name={`${label}-radio-buttons-group`}
            value={value.toString()}
            onChange={(event, value) => onChange(value === "true")}
        >
            <FormControlLabel
                disabled={readOnly}
                value
                control={
                    <Radio
                        sx={{color: "text.disabled"}}
                    />
                }
                label="Да"
            />
            <FormControlLabel
                disabled={readOnly}
                value={false}
                control={
                    <Radio
                        sx={{color: "text.disabled" }}
                    />
                }
                label="Нет"
            />
        </RadioGroup>
    </FormControl>
);

export default CheckBoxField;
