import {Tab, Tabs as MuiTabs} from "@mui/material";
import {FC} from "react";

type TabsProps = {
    step: number,
    onChange: (e: any, newValue: number) => void,
    items: Array<string>,
    isWidthFull?: boolean
}

const Tabs: FC<TabsProps> = ({items = [], step = 0, onChange, isWidthFull=false}) => (
    <MuiTabs
        color="brandColor.primary"
        value={step}
        onChange={onChange}
        sx={{mb: {xs: "1rem", md: "1.5rem"}, width: "100%"}}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
    >
        { items.length > 0 && items.map((item, index) => (
            <Tab color="brandColor.primary" sx={{pb: ".5rem", width: {xs: "50%", md: isWidthFull ? "50%" : "auto"}}} key={index} label={item} />
        ))}
    </MuiTabs>
)

export default Tabs;