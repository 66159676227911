
const BASE_URL = "https://backend.jol-almaty.kz/api";

const tabTypeItems = ["road_pits", "path_signs"];

const tabStatusItems = ["new", "in_work", "in_moderation", "repaired"];

export {
    tabTypeItems,
    BASE_URL,
    tabStatusItems
}