import {NavLinkType} from "./common-types";
import {ReactComponent as AnalyticsIcon} from "../assets/icons/menu-analitycs.svg";
import {ReactComponent as GeoIcon} from "../assets/icons/menu-geo.svg";
import {ReactComponent as ProfileIcon} from "../assets/icons/menu-profile.svg";
import {ReactComponent as StatsIcon} from "../assets/icons/menu-stats.svg";
import {ReactComponent as StaffIcon} from "../assets/icons/menu-staff.svg";
import {ReactComponent as TasksIcon} from "../assets/icons/menu-tasks.svg";


const navLinks: Array<NavLinkType> = [
    {
        label: "statistics",
        key: "main",
        href: "/",
        Icon: AnalyticsIcon
    },
    {
        label: "pits",
        key: "pits",
        href: "/pits",
        Icon: GeoIcon
    },
    {
        label: "tasks",
        key: "tasks",
        href: "/tasks",
        Icon: TasksIcon
    },
    // {
    //     label: "map",
    //     key: "map",
    //     href: "/map",
    //     Icon: MapIcon
    // },
    // {
    //     label: "map_road_signs",
    //     key: "road-signs",
    //     href: "/road-signs",
    //     Icon: RoadSignsIcon
    // },
    {
        label: "reports",
        key: "reports",
        href: "/reports",
        Icon: StatsIcon
    },
    {
        label: "performers",
        key: "performers",
        href: "/performers",
        Icon: StaffIcon
    },
    {
        label: "profile",
        key: "profile",
        href: "/profile",
        Icon: ProfileIcon
    }
]

export default navLinks;