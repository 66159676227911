import React, { FC } from "react";
import {
    OutlinedInput,
    InputLabel,
    MenuItem,
    Select,
    FormControl,
    Stack,
    Chip,
    FormHelperText,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import { FieldError } from "react-hook-form";
import {OptionObjectType} from "../../services/common-types";
import {parseLabelKey} from "../../services/helpers";

type MultiSelectProps = {
    label: string,
    value: string | string[] | any,
    onChange: (e: any) => void,
    hint?: string,
    type?: string,
    errors?: FieldError | any,
    options: Array<string | OptionObjectType>,
    rest: any
}

const MultiSelect: FC<MultiSelectProps> = ({
    label = "MultiSelect",
    value, 
    onChange, 
    hint = "",
    type = "type",
    errors = {},
    options,
    rest
}) => {
    const isError = Object.keys(errors).length > 0;
    return (
        <FormControl sx={{ height: "48px",  width: "100%", maxWidth: "360px", }}>
            <InputLabel htmlFor="outlined-input" sx={{color: isError ? "text.error" : undefined }}>{label}</InputLabel>
            <Select
                multiple
                value={value}
                onChange={onChange}
                error={isError}
                input={<OutlinedInput
                    label="Multiple Select"
                    sx={{
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "2px solid",
                            borderColor: "primary.main",
                        },
                    }}
                />}
                renderValue={selected => (
                    <Stack gap={1} direction="row" flexWrap="wrap">
                        {selected.map((selectedValue: string | OptionObjectType, index: number) => {
                            const {label, key} = parseLabelKey(selectedValue);
                            return (
                                <Chip
                                    key={index}
                                    label={label}
                                    onDelete={() => {
                                        if (Array.isArray(value)) {
                                            const newValue = value.filter(v => v !== key);
                                            onChange(newValue);
                                        }
                                    }}
                                    deleteIcon={<CancelIcon onMouseDown={event => event.stopPropagation()} />}
                                />
                            )
                        })}
                    </Stack>
                )}
            >
                {options.map((item: string | OptionObjectType, index: number) => {
                    const {label, key} = parseLabelKey(item);
                    return (
                        <MenuItem key={index} value={key}>
                            {label}
                            {value.includes(label) && <CheckIcon color="info" />}
                        </MenuItem>
                    )
                })}
            </Select>
            {isError && <FormHelperText sx={{color: "text.error"}} >{errors?.message}</FormHelperText>}
        </FormControl>
    )
}

export default MultiSelect;
