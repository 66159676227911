import React, {useEffect, useRef, useState} from "react";
import ApexCharts from "apexcharts";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import axios from "axios";
import dayjs from "dayjs";
import {getRadialChartOptions} from "../configs";
import {BASE_URL} from "../../../services/constants";
import PeriodChangeMenu from "./PeriodChangeMenu";

type RadialChartType = {
    chart: {
        data?: number[]
    },
    cards: {
        new?: number,
        in_work?: number,
        repaired?: number
    }
}

const RadialChart = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<RadialChartType>({
        chart: {},
        cards: {}
    });
    const chartInstance = useRef<any>(null);
    const [period, setPeriod] = useState(dayjs().add(-30, "day").format("YYYY-MM-DD"));

    useEffect(() => {
        const getChartOptions = async () => {
            try {
                setIsLoading(true);
                const res = await axios.get(`${BASE_URL}/detection/dashboard/donut_graph/`,
                    {params: {date_after: period, date_before: dayjs().format("YYYY-MM-DD")}}
                );
                setData(res.data);
            } catch (e) {
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        }
        getChartOptions();
    }, [period])

    useEffect(() => {
        if (data.chart.data && document.getElementById("radial-chart") && typeof ApexCharts !== "undefined") {
            if (chartInstance.current) {
                chartInstance.current.updateSeries(data.chart.data as Array<number>);
            } else {
                chartInstance.current = new ApexCharts(document.querySelector("#radial-chart"), getRadialChartOptions(data.chart.data,[t("repaired"), t("in_work"), t("waiting")]));
                chartInstance.current.render();
            }
        }
    }, [data.chart.data])

    if (!isLoading && Object.keys(data.cards).length === 0) {
        return null;
    }

    return (
        <div className="w-full md:w-[calc(100%/3)] h-fit bg-white rounded-lg shadow p-4 md:p-6">
            <div className="flex justify-between mb-3">
                <div className="flex items-center">
                    <div className="flex justify-center items-center">
                        <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white pe-1">{t("repairer_kpi")}</h5>
                        {/*<svg data-popover-target="chart-info" data-popover-placement="bottom"*/}
                        {/*    className="w-3.5 h-3.5 text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white cursor-pointer ms-1" aria-hidden="true"*/}
                        {/*    xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">*/}
                        {/*    <path*/}
                        {/*        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm0 16a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0*/}
                        {/*        3Zm1-5.034V12a1 1 0 0 1-2 0v-1.418a1 1 0 0 1 1.038-.999 1.436 1.436 0 0 0 1.488-1.441 1.501 1.501 0 1 0-3-.116.986.986 0*/}
                        {/*        0 1-1.037.961 1 1 0 0 1-.96-1.037A3.5 3.5 0 1 1 11 11.466Z"*/}
                        {/*    />*/}
                        {/*</svg>*/}
                        {/*<div data-popover id="chart-info" role="tooltip"*/}
                        {/*    className="absolute z-10 invisible inline-block text-sm text-gray-500 transition-opacity duration-300*/}
                        {/*    bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 w-72 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-400"*/}
                        {/*>*/}
                        {/*    <div className="p-3 space-y-2">*/}
                        {/*        <h3 className="font-semibold text-gray-900 dark:text-white">Activity growth - Incremental</h3>*/}
                        {/*        <p>Report helps navigate cumulative growth of community activities. Ideally, the chart should have a growing trend,
                        as stagnating chart signifies a significant decrease*/}
                        {/*            of community activity.</p>*/}
                        {/*        <h3 className="font-semibold text-gray-900 dark:text-white">Calculation</h3>*/}
                        {/*        <p>For each date bucket, the all-time volume of activities is calculated. This means that activities in period n contain all activities up to period n, plus the*/}
                        {/*            activities generated by your community in period.</p>*/}
                        {/*        <Link to="/" className="flex items-center font-medium text-blue-600 dark:text-blue-500
                        dark:hover:text-blue-600 hover:text-blue-700 hover:underline">Read more <svg*/}
                        {/*            className="w-2 h-2 ms-1.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">*/}
                        {/*            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>*/}
                        {/*        </svg></Link>*/}
                        {/*    </div>*/}
                        {/*    <div data-popper-arrow></div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>

            <div className="bg-gray-50 p-3 rounded-lg">
                <div className="grid grid-cols-3 gap-3 mb-2">
                    <dl className="bg-orange-50 rounded-lg flex flex-col items-center justify-center h-[78px]">
                        <dt className="w-8 h-8 rounded-full bg-orange-100 text-sm font-medium flex items-center justify-center mb-1">{data.cards.new}</dt>
                        <dd className="text-orange-600 text-sm font-medium">{t("waitings")}</dd>
                    </dl>
                    <dl className="bg-teal-50 dark:bg-gray-600 rounded-lg flex flex-col items-center justify-center h-[78px]">
                        <dt className="w-8 h-8 rounded-full bg-teal-100 text-sm font-medium flex items-center justify-center mb-1">{data.cards.in_work}</dt>
                        <dd className="text-teal-600 dark:text-teal-300 text-sm font-medium">{t("in_work")}</dd>
                    </dl>
                    <dl className="bg-blue-50 dark:bg-gray-600 rounded-lg flex flex-col items-center justify-center h-[78px]">
                        <dt className="w-8 h-8 rounded-full bg-blue-100 text-sm font-medium flex items-center justify-center mb-1">{data.cards.repaired}</dt>
                        <dd className="text-blue-600 text-sm font-medium">{t("repaired")}</dd>
                    </dl>
                </div>
                {/*<button data-collapse-toggle="more-details" type="button" className="hover:underline text-xs text-gray-500 dark:text-gray-400 font-medium inline-flex items-center">*/}
                {/*    Show more*/}
                {/*    details */}
                {/*    <svg className="w-2 h-2 ms-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">*/}
                {/*        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>*/}
                {/*    </svg>*/}
                {/*</button>*/}
                {/*<div id="more-details" className="border-gray-200 border-t dark:border-gray-600 pt-3 mt-3 space-y-2 hidden">*/}
                {/*    <dl className="flex items-center justify-between">*/}
                {/*        <dt className="text-gray-500 dark:text-gray-400 text-sm font-normal">Average task completion rate:</dt>*/}
                {/*        <dd className="bg-green-100 text-green-800 text-xs font-medium inline-flex items-center px-2.5 py-1 rounded-md dark:bg-green-900 dark:text-green-300">*/}
                {/*            <svg className="w-2.5 h-2.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 14">*/}
                {/*                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13V1m0 0L1 5m4-4 4 4"/>*/}
                {/*            </svg>*/}
                {/*            57%*/}
                {/*        </dd>*/}
                {/*    </dl>*/}
                {/*    <dl className="flex items-center justify-between">*/}
                {/*        <dt className="text-gray-500 dark:text-gray-400 text-sm font-normal">Days until sprint ends:</dt>*/}
                {/*        <dd className="bg-gray-100 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-1 rounded-md dark:bg-gray-600 dark:text-gray-300">13 days</dd>*/}
                {/*    </dl>*/}
                {/*    <dl className="flex items-center justify-between">*/}
                {/*        <dt className="text-gray-500 dark:text-gray-400 text-sm font-normal">Next meeting:</dt>*/}
                {/*        <dd className="bg-gray-100 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-1 rounded-md dark:bg-gray-600 dark:text-gray-300">Thursday</dd>*/}
                {/*    </dl>*/}
                {/*</div>*/}
            </div>

            <div className="py-2" id="radial-chart"></div>

            <div className="grid grid-cols-1 items-center border-gray-200 border-t justify-between">
                <div className="flex justify-between items-center pt-5">
                    <PeriodChangeMenu
                        name="radial"
                        period={period}
                        setPeriod={setPeriod}
                    />
                    <Link
                        to="/tasks"
                        className="uppercase text-sm font-semibold inline-flex items-center rounded-lg text-blue-600 
                        hover:text-blue-700 dark:hover:text-blue-500  hover:bg-gray-100 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 px-3 py-2"
                    >
                        {t("task_board")}
                        <svg className="w-2.5 h-2.5 ms-1.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
                        </svg>
                    </Link>
                </div>
            </div>
        </div>

    )
}

export default RadialChart;