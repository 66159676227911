import {Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import React from "react";
import "../../assets/styles/statistics.css";
import LineChart from "./Charts/LineChart";
import RadialChart from "./Charts/RadialChart";
import TabData from "./Charts/TabData";
import Repairers from "./Table/Repairers";

const Statistics = () => {
    const { t } = useTranslation();

    return (
        <Box>
            <Box sx={{display: {xs: "none", md: "flex"}, justifyContent: "space-between", alignItems: "center", mb: {xs: "1rem", md: "2rem"}}}>
                <Typography variant="h1" sx={{fontSize: {xs: "1.5rem", md: "2rem"}, fontWeight: "700"}}>{t("analytics")}</Typography>
            </Box>
            <Box sx={{mb: "1rem", display: "flex", flexDirection: {xs: "column", md: "row"}, gap: "1rem"}}>
                <Box sx={{width: {xs: "100%", md: "calc(100%/3)"}, display: "flex", gap: "1rem", flexDirection: "column"}}>
                    <LineChart name="line-2" />
                    <LineChart isNewDefects={false} name="line-1" />
                </Box>
                <RadialChart />
                <TabData />
            </Box>
            <Repairers />
        </Box>
    )
}

export default Statistics;