import {Box, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import axios from "axios";
import {DataGrid, GridSingleSelectColDef} from "@mui/x-data-grid";
import {Link} from "react-router-dom";
import {BASE_URL} from "../../../services/constants";
import PeriodChangeMenu from "../Charts/PeriodChangeMenu";

const headers = [
    {
        key: "id",
        label: "id"
    },
    {
        key: "name",
        label: "name"
    },
    {
        key: "new_defects",
        label: "new_defects"
    },
    {
        key: "inwork_defects",
        label: "inwork_defects"
    },
    {
        key: "in_moderation_defects",
        label: "in_moderation_defects"
    },
    {
        key: "repaired_defects",
        label: "repaired_defects"
    }
]

const Repairers = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState<GridSingleSelectColDef[]>([]);
    const [period, setPeriod] = useState(dayjs().add(-30, "day").format("YYYY-MM-DD"));

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const res = await axios.get(`${BASE_URL}/detection/dashboard/table/`,
                    {params: {date_after: period, date_before: dayjs().format("YYYY-MM-DD")}}
                );
                const cols = headers.map((col: any) => ({
                    ...col,
                    field: col.key,
                    headerName: t(col.label),
                    flex: col.key === "id" ? 0 : 1,
                    minWidth: col.key === "id" ? 50 : 200
                }))
                setRows(res.data);
                // @ts-ignore
                setColumns(cols);
            } catch (err) {
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        })()
    }, [period])

    return (
        <Box sx={{marginBottom: "1rem", p: "1.5rem", borderRadius: ".5rem", background: "#fff", boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.1)"}}>
            <Typography variant="h1" sx={{mb: "1.25rem", fontSize: "1.25rem", fontWeight: "700"}}>{t("repairers")}</Typography>
            <DataGrid
                sx={{
                    zIndex: "1001",
                    border: "none",
                    ".MuiDataGrid-virtualScrollerRenderZone": {
                        transform: "none !important"
                    },
                    ".MuiDataGrid-cell": {
                        border: "none",
                        pl: "0",
                    },
                    ".MuiDataGrid-row": {
                        gap: ".75rem"
                    },
                    ".css-yrdy0g-MuiDataGrid-columnHeaderRow": {
                        gap: ".75rem"
                    },
                    ".MuiDataGrid-columnHeader": {
                        pl: "0"
                    },
                    ".MuiDataGrid-columnHeaders": {
                        border: "none"
                    },
                    ".MuiDataGrid-footerContainer": {
                        border: "none"
                    },
                    ".MuiDataGrid-columnHeaderTitle": {
                        color: "text.secondary"
                    }
                }}
                disableVirtualization
                getRowSpacing={() => ({ top: 12, bottom: 12 })}
                getRowHeight={() => "auto"}
                // autoHeight
                disableColumnMenu
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        },
                    },
                }}
                pageSizeOptions={[10, 25, 50, 100]}
                loading={isLoading}
                hideFooterSelectedRowCount
                componentsProps={{
                    pagination: {
                        labelRowsPerPage: t("rows_per_page")
                    }
                }}
            />
            <div className="grid grid-cols-1 items-center border-gray-200 border-t justify-between">
                <div className="flex justify-between items-center pt-5">
                    <PeriodChangeMenu
                        name="radial"
                        period={period}
                        setPeriod={setPeriod}
                    />
                    <Link
                        to="/tasks"
                        className="uppercase text-sm font-semibold inline-flex items-center rounded-lg text-blue-600
                        hover:text-blue-700 dark:hover:text-blue-500  hover:bg-gray-100 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 px-3 py-2"
                    >
                        {t("task_board")}
                        <svg className="w-2.5 h-2.5 ms-1.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
                        </svg>
                    </Link>
                </div>
            </div>
        </Box>
    )
}

export default Repairers;