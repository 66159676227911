const MapPinIcon = ({size="16", color="#6B7280"}: any) => (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.99983 1.83338C7.02751 1.83243 6.07347 2.09757 5.24107 2.60007C4.40866 3.10257 3.72964 3.82328
        3.27756 4.68411C2.82547 5.54494 2.61758 6.51307 2.67638 7.48361C2.73519 8.45415 3.05846 9.39009 3.61116
        10.19C3.63329 10.2335 3.66012 10.2744 3.69116 10.312L3.77116 10.4094C3.84583 10.506 3.9225 10.5994 3.9885
        10.676L7.48516 14.9254C7.54786 15.001 7.62651 15.0619 7.71549 15.1036C7.80446 15.1454 7.90156 15.1669 7.99983
        15.1667C8.09837 15.1668 8.1957 15.145 8.28481 15.1029C8.37392 15.0608 8.45259 14.9995 8.51516 14.9234L11.9098
        10.7867C12.0473 10.6389 12.176 10.483 12.2952 10.32L12.3798 10.2167C12.4121 10.1775 12.4394 10.1345 12.4612
        10.0887C12.988 9.28427 13.2875 8.35236 13.3281 7.39162C13.3686 6.43087 13.1487 5.47703 12.6915 4.63106C12.2343
        3.78509 11.5569 3.07846 10.731 2.58597C9.90513 2.09347 8.96143 1.83343 7.99983 1.83338ZM7.99983 5.16671C8.39539
        5.16671 8.78207 5.28401 9.11097 5.50377C9.43987 5.72353 9.69622 6.03589 9.84759 6.40134C9.99897 6.7668 10.0386
        7.16893 9.9614 7.55689C9.88423 7.94485 9.69375 8.30122 9.41405 8.58092C9.13434 8.86063 8.77797 9.05111 8.39001
        9.12828C8.00205 9.20545 7.59992 9.16584 7.23446 9.01447C6.86901 8.86309 6.55666 8.60675 6.33689 8.27785C6.11713
        7.94895 5.99983 7.56227 5.99983 7.16671C5.99983 6.63628 6.21054 6.12757 6.58562 5.7525C6.96069 5.37742 7.4694
        5.16671 7.99983 5.16671Z" fill={color}/>
    </svg>
)

export default MapPinIcon;