import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import Brightness5OutlinedIcon from "@mui/icons-material/Brightness5Outlined";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import * as React from "react";
import {useTheme} from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import {FC, useContext} from "react";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import ItemsList from "./ItemsList";
import {AppBar, Drawer, DrawerHeaderDesktop} from "./constants";
import {DrawerProps} from "./type";
import Localization from "../Localization";
import TextLogo from "../../UI/TextLogo";
import CustomButton from "../../UI/CustomButton";
import {AuthContext} from "../../context/AuthContext";
import {ReactComponent as JolAI} from "../../assets/icons/jolai-icon.svg";

const DrawerDesktop: FC<DrawerProps> = ({logout}) => {
    const { t } = useTranslation();
    const {toggleColorMode} = useContext(AuthContext);
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Drawer variant="permanent" open={open} sx={{display: {xs: "none", md: "flex", }, zIndex: 100}}>
            <DrawerHeaderDesktop sx={{justifyContent: "space-between"}}>
                { open ?
                    <>
                        <Link to="/" style={{ height: "47px", marginLeft: "1.25rem", display: "flex", gap: ".5rem", alignItems: "end"}}>
                            <JolAI className="mb-[2px]"/>
                            <Typography color="brandColor" sx={{fontWeight: "600", fontSize: "18px"}}>
                                Jol<Typography component="span" color="brandColor.primary" sx={{fontSize: "18px", fontWeight: "600"}}>AI</Typography>
                            </Typography>
                        </Link>
                        <Box sx={{display: "flex", alignItems: "end", mr: "1rem", height: "40px"}}>
                            <Localization />
                        </Box>
                        {/*<IconButton onClick={handleDrawerClose}>*/}
                        {/*    <ChevronLeftIcon sx={{ color: "text.primary" }}/>*/}
                        {/*</IconButton>*/}
                    </>
                    :
                    <Box sx={{ height: "45px", ml: "1rem"}}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                ...(!open ? { marginRight: 5 } : { display: "none" }),
                                padding: "8px"
                            }}
                        >
                            <MenuIcon sx={{ color: "text.primary" }} />
                        </IconButton>
                    </Box>

                }
            </DrawerHeaderDesktop>
            <ItemsList open={open} logout={logout} />
            {/*{*/}
            {/*    open && <Typography sx={{ position: "fixed", bottom: "16px", alignSelf: "center", textAlign: "center" }}>*/}
            {/*        Алматы<br/>*/}
            {/*        © 2024 JolAI*/}
            {/*    </Typography>*/}
            {/*}*/}
        </Drawer>
    )
}

export default DrawerDesktop;