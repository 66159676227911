const ListIcon = ({size="16", color="#6B7280"}: any) => (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.08331 4.5H2.24998C2.01986 4.5 1.83331 4.67909 1.83331 4.9V5.7C1.83331 5.92091
            2.01986 6.1 2.24998 6.1H3.08331C3.31343 6.1 3.49998 5.92091 3.49998 5.7V4.9C3.49998 4.67909 3.31343
            4.5 3.08331 4.5Z" fill={color}/>
        <path d="M3.08331 7.7H2.24998C2.01986 7.7 1.83331 7.87909 1.83331 8.1V8.9C1.83331 9.12091
            2.01986 9.3 2.24998 9.3H3.08331C3.31343 9.3 3.49998 9.12091 3.49998 8.9V8.1C3.49998 7.87909
            3.31343 7.7 3.08331 7.7Z" fill={color}/>
        <path d="M3.08331 10.9H2.24998C2.01986 10.9 1.83331 11.0791 1.83331 11.3V12.1C1.83331 12.3209
            2.01986 12.5 2.24998 12.5H3.08331C3.31343 12.5 3.49998 12.3209 3.49998 12.1V11.3C3.49998
            11.0791 3.31343 10.9 3.08331 10.9Z" fill={color}/>
        <path d="M14.3333 6.1H5.99998C5.77897 6.1 5.567 6.01571 5.41072 5.86569C5.25444 5.71566
            5.16665 5.51217 5.16665 5.3C5.16665 5.08783 5.25444 4.88434 5.41072 4.73431C5.567 4.58429 5.77897
            4.5 5.99998 4.5H14.3333C14.5543 4.5 14.7663 4.58429 14.9226 4.73431C15.0788 4.88434 15.1666
            5.08783 15.1666 5.3C15.1666 5.51217 15.0788 5.71566 14.9226 5.86569C14.7663 6.01571 14.5543
            6.1 14.3333 6.1Z" fill={color}/>
        <path d="M14.3333 9.3H5.99998C5.77897 9.3 5.567 9.21571 5.41072 9.06569C5.25444 8.91566 5.16665
            8.71217 5.16665 8.5C5.16665 8.28783 5.25444 8.08434 5.41072 7.93431C5.567 7.78429 5.77897
                7.7 5.99998 7.7H14.3333C14.5543 7.7 14.7663 7.78429 14.9226 7.93431C15.0788 8.08434
                15.1666 8.28783 15.1666 8.5C15.1666 8.71217 15.0788 8.91566 14.9226 9.06569C14.7663 9.21571
                14.5543 9.3 14.3333 9.3Z" fill={color}/>
        <path d="M14.3333 12.5H5.99998C5.77897 12.5 5.567 12.4157 5.41072 12.2657C5.25444 12.1157 5.16665
            11.9122 5.16665 11.7C5.16665 11.4878 5.25444 11.2843 5.41072 11.1343C5.567 10.9843 5.77897
            10.9 5.99998 10.9H14.3333C14.5543 10.9 14.7663 10.9843 14.9226 11.1343C15.0788 11.2843
            15.1666 11.4878 15.1666 11.7C15.1666 11.9122 15.0788 12.1157 14.9226 12.2657C14.7663 12.4157 14.5543
            12.5 14.3333 12.5Z" fill={color}/>
    </svg>
)

export default ListIcon;

