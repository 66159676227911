import React from "react";
import {RouterProvider} from "react-router-dom";
import router from "../router";
import RootLayout from "../layouts/RootLayout";
import "../assets/styles/global.css";

const App = () => (
    <RootLayout>
        <RouterProvider router={router} />
    </RootLayout>
)
export default App;
