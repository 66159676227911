import * as React from "react";
import {Box, Typography} from "@mui/material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import {FC, useContext} from "react";
import Brightness5OutlinedIcon from "@mui/icons-material/Brightness5Outlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import CloseIcon from "@mui/icons-material/Close";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import TextLogo from "../../UI/TextLogo";
import ItemsList from "./ItemsList";
import {DrawerProps} from "./type";
import {AuthContext} from "../../context/AuthContext";
import CustomButton from "../../UI/CustomButton";
import Localization from "../Localization";
import {ReactComponent as QuitIcon} from "../../assets/icons/menu-quit.svg";
import JolAI from "../../assets/icons/jolai-icon.svg";

const DrawerMobile: FC<DrawerProps> = ({logout}) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const {toggleColorMode} = useContext(AuthContext);
    const theme = useTheme();

    const toggleDrawer = (open: boolean) => {
        setOpen(open);
    };

    const list = () => (
        <Box
            sx={{ width: 250, display: "flex", flexDirection: "column", height: "100%", padding: "16px 0"}}
            role="presentation"
            onClick={() => toggleDrawer( false)}
        >
            <Box color="inherit" sx={{ display: "flex", height: "40px", justifyContent: "space-between", alignItems: "center", paddingX: "16px", width: "100%" }}>
                <Typography color="brandColor" sx={{fontWeight: "600", fontSize: "1.5rem"}}>
                    JolAI
                </Typography>
                <CloseIcon />
            </Box>
            <ItemsList open={open} logout={logout}/>
            {/*<Typography sx={{ position: "fixed", bottom: "16px", alignSelf: "center", textAlign: "center" }}>*/}
            {/*    <CustomButton style={{width: "100%", mb: "1rem", backgroundColor: "red", ":hover": {backgroundColor: "red", opacity: ".75"}}}*/}
            {/*        label={t("logout")} onChange={logout}*/}
            {/*    />*/}
            {/*    Алматы <br/>*/}
            {/*    © 2024 JolAI*/}
            {/*</Typography>*/}
        </Box>
    );

    return (
        <Box sx={{ backgroundColor: "background.default", display: {xs: "flex", md: "none"}, height: "48px", zIndex: "12", width: "100%", position: "fixed",
            top: "0", left: "0", padding: "12px 16px", justifyContent: "space-between"}}
        >
            <Box sx={{ display: "flex", alignItems: "center", gap: "16px"}}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => toggleDrawer(true)}
                >
                    <MenuIcon/>
                </IconButton>
            </Box>
            <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", }}>
                <Link to="/" style={{ display: "flex", gap: ".5rem", alignItems: "center"}}>
                    <img
                        alt="logo"
                        src={JolAI}
                    />
                    <Typography color="brandColor" sx={{fontWeight: "600", fontSize: "18px"}}>
                        Jol<Typography component="span" color="brandColor.primary" sx={{fontSize: "18px", fontWeight: "600"}}>AI</Typography>
                    </Typography>
                </Link>
            </Box>
            <Box sx={{display: "flex", flexDirection: "row", backgroundColor: "background.default" }}>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", gap: "16px"}}>
                    <Localization />
                    <IconButton onClick={logout}>
                        <QuitIcon />
                    </IconButton>
                    {/*<IconButton onClick={toggleColorMode} color="inherit">*/}
                    {/*    {theme.palette.mode === "dark" ? <Brightness5OutlinedIcon /> : <DarkModeOutlinedIcon />}*/}
                    {/*</IconButton>*/}
                    {/*<IconButton disableRipple onClick={logout} color="inherit" sx={{display: "flex", alignItems: "center"}}>*/}
                    {/*    <AccountCircleRoundedIcon sx={{color: "customBackground.inverse", width: "32px", height: "32px"}} />*/}
                    {/*</IconButton>*/}
                </Box>
            </Box>
            <React.Fragment key="left">
                <SwipeableDrawer
                    anchor="left"
                    open={open}
                    onClose={() => toggleDrawer(false)}
                    onOpen={() => toggleDrawer(true)}
                >
                    {list()}
                </SwipeableDrawer>
            </React.Fragment>
        </Box>
    );
}

export default DrawerMobile;